import { CampaignStatusEnum } from "@/app/enums/campaign.enum";
import { CampaignProps } from "@/types/campaign";
import { Email, ThreadEmail } from "@/types/inbox";

// Add this helper function at the top level
const randomElement = <T>(array: T[]): T => {
	return array[Math.floor(Math.random() * array.length)];
};

export async function generateCampaignData(n: number): Promise<CampaignProps[]> {
	const campaignData: CampaignProps[] = [];
	const statuses = Object.values(CampaignStatusEnum);

	for (let i = 0; i < n; i++) {
		const sent = Math.floor(Math.random() * 2000) + 500;
		const opened = Math.floor(Math.random() * sent);
		const clicked = Math.floor(Math.random() * opened);
		const replied = Math.floor(Math.random() * clicked);
		const positiveReplies = Math.floor(Math.random() * replied);
		const randomStatus = statuses[Math.floor(Math.random() * statuses.length)] as CampaignStatusEnum;

		campaignData.push({
			id: `campaign_${i + 1}`,
			title: `New campaign ${i + 1}`,
			status: randomStatus,
			activeStepId: "",
			sequence: [
				{
					id: "seq-" + new Date().getTime(),
					order: 0,
					subject: "Email subject",
					html: `
<h2>
    🚀 Hello there, Future Campaign Master! 🚀
</h2>
<p>
    🎉 Welcome to the <strong>heart</strong> of Za-zu, where marketing campaigns spring to life. Imagine this as your command center, where you control the fate of your digital messages. Let's dive into creating a campaign, shall we?
</p>
<ul>
    <li>
    📂 Start by importing leads. You've got options:
    <ul>
        <li>🔽 Upload a CSV, or</li>
        <li>🔗 Connect via API (for the tech-savvy folks)</li>
    </ul>
    </li>
    <li>
    💌 Next up, create a sequence. Think of it as choreographing a dance, but for emails:
    <ul>
        <li>➕ Add multiple steps,</li>
        <li>⏱ Each with its own email and a delay timer. It's like setting up dominoes!</li>
    </ul>
    </li>
</ul>
<p>
    🎯 Ready to launch? Hit the final review, choose your inboxes, and send a test email to make sure everything's peachy.
</p>
<blockquote>
    “Wow, that’s amazing. You’re practically a digital wizard now!” 👏
    <br />
    — Probably Your Future Self
</blockquote>`,
					delayInDays: 3,
				},
			],
			client_id: `client_${i + 1}`,
			workspace_id: `workspace_${i + 1}`,
			created_by_user_id: `user_${i + 1}`,
			date_created: new Date().toISOString(),
			date_updated: new Date().toISOString(),
			mailbox_ids: [`mailbox_${i + 1}`],
			custom_field_requirements: {
				field_1: true,
				field_2: false,
			},

			campaignMetrics: {
				sent: sent,
				clicked: clicked,
				clickedPct: ((clicked / sent) * 100).toFixed(2) + "%",
				opened: opened,
				openedPct: ((opened / sent) * 100).toFixed(2) + "%",
				replied: replied,
				repliedPct: ((replied / sent) * 100).toFixed(2) + "%",
				positiveReplies: positiveReplies,
				positiveRepliesPct: ((positiveReplies / replied) * 100).toFixed(2) + "%",
			},
		});
	}

	return campaignData;
}

// Add a type for contact info
type ContactInfo = {
	name: string;
	email: string;
	companyName: string;
};

// Define consistent contacts
const contacts: ContactInfo[] = [
	{ name: "Dianna Doe", email: "dianna@example.com", companyName: "Apple" },
	{ name: "Courtney Henry", email: "courtney@example.com", companyName: "Microsoft" },
	{ name: "Darlene Robertson", email: "darlene@example.com", companyName: "Google" },
	{ name: "Jane Cooper", email: "jane@example.com", companyName: "Meta" },
	{ name: "Albert Flores", email: "albert@example.com", companyName: "Amazon" },
];

const ME: ContactInfo = { name: "Me", email: "me@za-zu.com", companyName: "Za-zu" };

// Update the thread generator to use contact info
const generateRandomThread = (contact: ContactInfo, parentSubject: string): ThreadEmail[] => {
	const threadLength = Math.floor(Math.random() * 8) + 3; // 3 to 10 emails in thread
	return Array.from({ length: threadLength }, (_, i) => ({
		id: i + 1,
		from: i % 2 === 0 ? contact.name : ME.name,
		to: i % 2 === 0 ? ME.name : contact.name,
		fromEmail: i % 2 === 0 ? contact.email : ME.email,
		toEmail: i % 2 === 0 ? ME.email : contact.email,
		subject: i === 0 ? parentSubject : `Re: ${parentSubject}`,
		content: `This is email ${i + 1} in the thread. ${randomElement(sampleMessages)}`,
		timestamp: new Date(
			Date.now() - (threadLength - i) * Math.floor(Math.random() * 24 * 60 * 60 * 1000),
		).toISOString(),
		attachments: Math.random() > 0.7 ? "2" : undefined,
	}));
};

// Add these sample messages at the top with the other constants
const sampleMessages = [
	`I hope this email finds you well. I recently came across Za-zu's innovative solutions in the market automation space and I'm thoroughly impressed by your approach to scaling outbound communications. Our team at TechCorp has been looking for ways to optimize our outreach efforts, and your platform seems to perfectly address our needs.

I'm particularly interested in understanding how Za-zu handles email warming and rotation across multiple inboxes. Could you provide more details about your infrastructure and how it ensures deliverability at scale? Also, I'd love to learn more about your pricing model for enterprise-level implementations.

Would you be available for a brief call next week to discuss this further? I believe there could be significant potential for collaboration here.

Looking forward to your response.

Best regards`,

	`Thank you for reaching out about our enterprise solution requirements. Your presentation last week was quite compelling, and I've had the chance to review it in detail with our team. We're especially intrigued by Za-zu's ability to maintain high deliverability rates while scaling outbound campaigns.

However, we have a few questions about implementation:
1. How does your system integrate with our existing CRM?
2. What kind of analytics and reporting capabilities are included?
3. Can we set custom rules for email rotation and warming?

Could you provide some clarity on these points? Also, it would be helpful to see a detailed breakdown of your enterprise pricing structure.

Thanks in advance`,

	`I've been following Za-zu's growth in the market automation space with great interest. Your recent case study about helping companies scale their outbound efforts without compromising deliverability really caught my attention.

Our company is currently struggling with exactly the problems you solve - we're hitting scaling limitations with our current outbound strategy and our sales team is overwhelmed. I'd love to learn more about how Za-zu could help us overcome these challenges.

Could you share some specific examples of how other companies in our industry have implemented your solution? Also, what kind of onboarding support do you provide?

Looking forward to hearing from you.

Best regards`,
];

const sampleSubjects = [
	"Re: Partnership Opportunity with Za-zu",
	"Following up on our conversation",
	"Quick question about Za-zu's enterprise solution",
	"Interested in scaling our outbound efforts",
	"Za-zu Implementation Questions",
];

// Update the generateRandomEmails function
export async function generateRandomEmails(numEmails: number = 10): Promise<Email[]> {
	const campaignTitles = ["Campaign 1", "Campaign 2", "Campaign 3", "Campaign 4", "Campaign 5"];

	const inbox: Email[] = [];
	const emailsWithToday = Math.min(numEmails, 3);

	// Helper function to get preview from full message
	const getPreview = (message: string): string => {
		return message.split("\n")[0] + "...";
	};

	// Generate today's emails
	for (let i = 0; i < emailsWithToday; i++) {
		const contact = randomElement(contacts);
		const fullMessage = randomElement(sampleMessages);

		inbox.push({
			id: i + 1,
			name: contact.name,
			companyName: contact.companyName,
			fromEmail: contact.email,
			toEmail: ME.email,
			subject: randomElement(sampleSubjects),
			messagePreview: getPreview(fullMessage),
			fullMessage: fullMessage,
			campaignTitle: randomElement(campaignTitles),
			timestamp: getTodayDateWithRandomTime(),
			thread: generateRandomThread(contact),
		});
	}

	// Generate older emails
	for (let i = emailsWithToday; i < numEmails; i++) {
		const contact = randomElement(contacts);
		const fullMessage = randomElement(sampleMessages);

		inbox.push({
			id: i + 1,
			name: contact.name,
			companyName: contact.companyName,
			fromEmail: contact.email,
			toEmail: ME.email,
			subject: randomElement(sampleSubjects),
			messagePreview: getPreview(fullMessage),
			fullMessage: fullMessage,
			campaignTitle: randomElement(campaignTitles),
			timestamp: getRandomDateExcludingToday(),
			thread: generateRandomThread(contact),
		});
	}

	return inbox;
}

const getRandomDateExcludingToday = (): Date => {
	const now = new Date();
	const start = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000); // 30 days ago
	const today = new Date(now.getFullYear(), now.getMonth(), now.getDate()); // Today

	// Generate a random time between start and yesterday
	const randomTime = start.getTime() + Math.random() * (today.getTime() - start.getTime());
	const randomDate = new Date(randomTime);

	randomDate.setHours(Math.floor(Math.random() * 24));
	randomDate.setMinutes(Math.floor(Math.random() * 60));
	randomDate.setSeconds(Math.floor(Math.random() * 60));
	randomDate.setMilliseconds(0);

	return randomDate;
};

const getTodayDateWithRandomTime = (): Date => {
	const now = new Date();
	const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());

	// Assign a random time within today
	today.setHours(Math.floor(Math.random() * 24));
	today.setMinutes(Math.floor(Math.random() * 60));
	today.setSeconds(Math.floor(Math.random() * 60));
	today.setMilliseconds(0);

	return today;
};
