import { useEffect, useRef, useState } from "react";
import IconSVG from "../IconSVG/IconSVG";

type SearchBarProps = {
	searchText: string;
	setSearchText: (searchText: string) => void;
};

const SearchBar = ({ searchText, setSearchText }: SearchBarProps) => {
	const inputRef = useRef<HTMLInputElement>(null);
	const [isFocused, setIsFocused] = useState(false);

	useEffect(() => {
		const handleKeyDown = (event: KeyboardEvent) => {
			const activeElement = document.activeElement;
			const isInputField =
				activeElement instanceof HTMLInputElement ||
				activeElement instanceof HTMLTextAreaElement ||
				(activeElement as HTMLElement)?.isContentEditable;

			// Handle "/" key for focus
			if (event.key === "/" && !isInputField) {
				event.preventDefault();
				inputRef.current?.focus();
			}

			// Handle ESC key for blur
			if (event.key === "Escape" && activeElement === inputRef.current) {
				event.preventDefault();
				inputRef.current?.blur();
				setSearchText(""); // Optional: clear search text when escaping
			}
		};

		document.addEventListener("keydown", handleKeyDown);

		return () => {
			document.removeEventListener("keydown", handleKeyDown);
		};
	}, []);

	const searchIconPath =
		"M7.33333 3.33333C5.12419 3.33333 3.33333 5.12419 3.33333 7.33333C3.33333 9.54247 5.12419 11.3333 7.33333 11.3333C9.54247 11.3333 11.3333 9.54247 11.3333 7.33333C11.3333 5.12419 9.54247 3.33333 7.33333 3.33333ZM2 7.33333C2 4.38781 4.38781 2 7.33333 2C10.2789 2 12.6667 4.38781 12.6667 7.33333C12.6667 8.5658 12.2486 9.70067 11.5466 10.6037L13.8047 12.8619C14.0651 13.1223 14.0651 13.5444 13.8047 13.8047C13.5444 14.0651 13.1223 14.0651 12.8619 13.8047L10.6037 11.5466C9.70067 12.2486 8.5658 12.6667 7.33333 12.6667C4.38781 12.6667 2 10.2789 2 7.33333Z";

	return (
		<div className="flex h-8 w-full items-center gap-2 rounded-[6px] border-[0.5px] border-[#D9D9D9] bg-white px-2 shadow-[0px_1px_1px_0px_rgba(0,0,0,0.06),0px_4px_4px_-1px_rgba(0,0,0,0.02)]">
			<IconSVG
				path={searchIconPath}
				width={16}
				height={16}
				fill="var(--label-label-muted, color(display-p3 0.4000 0.4000 0.4000))"
				className="flex-shrink-0"
			/>
			<input
				ref={inputRef}
				type="text"
				placeholder="Search..."
				value={searchText}
				onChange={e => setSearchText(e.target.value)}
				onFocus={() => setIsFocused(true)}
				onBlur={() => setIsFocused(false)}
				className="w-full bg-transparent text-sm outline-none"
			/>
			<div className={`${isFocused ? "w-[32px]" : "min-w-[16px]"}`}>
				<kbd>{isFocused ? "esc" : "/"}</kbd>
			</div>
		</div>
	);
};

export default SearchBar;
