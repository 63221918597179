import React, { forwardRef, memo, useEffect } from "react";
import { ThreadEmail } from "@/types/inbox";
import { EmailViewState } from "@/types/email";
import { formatTimestamp } from "@/utils/helpers";
import clsx from "clsx";
import { EmailReplyForm } from "./EmailReplyForm";
import reply from "@/assets/icons/images/reply.png";
import forward from "@/assets/icons/images/forward.png";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";

const ME = { name: "Me", email: "me@za-zu.com" };

interface EmailThreadItemProps {
	threadEmail: ThreadEmail;
	index: number;
	isExpanded: boolean;
	isActive: boolean;
	isReplying: boolean;
	messageText: string;
	fullMessage?: string;
	onSelect: () => void;
	onReply: () => void;
	onMessageChange: (text: string) => void;
	onSend: (markAsDone?: boolean) => void;
	onDiscard: () => void;
	isHovered: boolean;
	onMouseEnter: () => void;
	onMouseLeave: () => void;
	textareaRef?: React.RefObject<HTMLTextAreaElement>;
}

export const EmailThreadItem = memo(
	forwardRef<HTMLDivElement, EmailThreadItemProps>(
		(
			{
				threadEmail,
				index,
				isExpanded,
				isActive,
				isReplying,
				messageText,
				fullMessage,
				onSelect,
				onReply,
				onMessageChange,
				onSend,
				onDiscard,
				isHovered,
				onMouseEnter,
				onMouseLeave,
				textareaRef,
			},
			ref,
		) => {
			if (!threadEmail) {
				return <div className="p-4">Email not found</div>;
			}

			const getFirstName = (fullName: string): string => fullName.split(" ")[0];

			const viewState: EmailViewState = isExpanded
				? { type: "expanded", isActive, isReplying }
				: { type: "collapsed", isActive };

			useEffect(() => {
				if (isActive && ref && "current" in ref && ref.current) {
					ref.current.focus();
				}
			}, [isActive, ref]);

			if (viewState.type === "collapsed") {
				return (
					<div
						ref={ref}
						tabIndex={0}
						className={clsx(
							"relative flex h-12 max-w-[768px] flex-shrink-0 cursor-pointer items-center gap-3 rounded-[10px] outline-none",
							isActive && "bg-background-bg-dim pl-4 pr-4",
							!isActive && isHovered && "bg-background-bg-base-hover px-4",
							!isActive && !isHovered && "px-4",
						)}
						onClick={onSelect}
						onMouseMove={onMouseEnter}
						onMouseLeave={onMouseLeave}>
						{isActive && <div className="bg-label-link absolute left-0 top-4 h-4 w-0.5 rounded-r-[2px]" />}
						<span className="font-inter max-w-[124px] flex-shrink-0 text-[13px] font-medium leading-[18px] text-[#292929]">
							{threadEmail.from === ME.email ? "Me" : getFirstName(threadEmail.from)}
						</span>
						<span className="font-inter flex-1 overflow-hidden text-ellipsis whitespace-nowrap text-[13px] font-normal leading-[18px] text-[#292929]">
							{fullMessage || threadEmail.content}
						</span>
						<time className="text-label-label-muted" dateTime={new Date(threadEmail.timestamp).toISOString()}>
							{formatTimestamp(threadEmail.timestamp, false)}
						</time>
					</div>
				);
			}

			return (
				<div
					ref={ref}
					tabIndex={0}
					className={clsx(
						"bg-background-bg-base shadow-float flex max-w-[768px] flex-col items-start overflow-hidden rounded-[10px] outline-none",
					)}
					onMouseEnter={onMouseEnter}
					onMouseLeave={onMouseLeave}>
					<div className="relative w-full">
						{isActive && !isReplying && (
							<div className="bg-label-link absolute bottom-5 left-0 top-4 w-0.5 rounded-r-[2px]" />
						)}
						<div
							className="flex flex-col items-start gap-5 p-4"
							onClick={onSelect}
							role="button"
							tabIndex={0}
							onKeyDown={e => {
								if (e.key === "Enter" || e.key === " ") {
									onSelect();
								}
								if (e.key.toLowerCase() === "r") {
									onReply();
								}
							}}>
							{viewState.type === "expanded" ? (
								<div className="flex w-full cursor-pointer items-center gap-3 self-stretch">
									<span className="text-label-label-base text-preview leading-preview font-medium">
										{threadEmail.from === ME.email
											? `Me to ${getFirstName(threadEmail.to)}`
											: `${getFirstName(threadEmail.from)} to Me`}
									</span>
									<span className="flex-1"></span>
									<time
										className="text-label-label-muted text-mini leading-mini line-clamp-1 flex items-center overflow-hidden text-ellipsis text-right font-normal"
										dateTime={new Date(threadEmail.timestamp).toISOString()}>
										{formatTimestamp(threadEmail.timestamp, viewState.type === "expanded")}
									</time>
									<div className="flex items-center gap-[2px] rounded-lg">
										<Tooltip>
											<TooltipTrigger asChild>
												<button
													className="hover:bg-background-bg-dim flex h-[20px] w-[20px] items-center justify-center gap-1 rounded-[5px] p-0 px-1"
													onClick={e => {
														e.stopPropagation();
														onReply();
													}}>
													<img src={reply} alt="Reply" className="h-[14px] w-[14px] flex-shrink-0" />
												</button>
											</TooltipTrigger>
											<TooltipContent>
												Reply <kbd className="ml-1 rounded border px-1 py-0.5 text-xs">R</kbd>
											</TooltipContent>
										</Tooltip>

										<Tooltip>
											<TooltipTrigger asChild>
												<button
													className="hover:bg-background-bg-dim flex h-[20px] w-[20px] items-center justify-center gap-1 rounded-[5px] p-0 px-1"
													onClick={e => e.stopPropagation()}>
													<img src={forward} alt="Forward" className="h-[14px] w-[14px] flex-shrink-0" />
												</button>
											</TooltipTrigger>
											<TooltipContent>
												Forward <kbd className="ml-1 rounded border px-1 py-0.5 text-xs">F</kbd>
											</TooltipContent>
										</Tooltip>
									</div>
								</div>
							) : (
								<div className="flex w-full cursor-pointer items-center gap-3 self-stretch">
									<span className="text-label-label-base text-preview leading-preview font-medium">
										{threadEmail.from === ME.email
											? `Me to ${getFirstName(threadEmail.to)}`
											: `${getFirstName(threadEmail.from)} to Me`}
									</span>
									<span className="flex-1"></span>
									<time
										className="text-label-label-muted text-mini leading-mini line-clamp-1 flex items-center overflow-hidden text-ellipsis text-right font-normal"
										dateTime={new Date(threadEmail.timestamp).toISOString()}>
										{formatTimestamp(threadEmail.timestamp, viewState.type === "expanded")}
									</time>
									<div className="flex items-center gap-[2px] rounded-lg">
										<Tooltip>
											<TooltipTrigger asChild>
												<button
													className="hover:bg-background-bg-dim flex h-[20px] w-[20px] items-center justify-center gap-1 rounded-[5px] p-0 px-1"
													onClick={e => {
														e.stopPropagation();
														onReply();
													}}>
													<img src={reply} alt="Reply" className="h-[14px] w-[14px] flex-shrink-0" />
												</button>
											</TooltipTrigger>
											<TooltipContent>
												Reply <kbd className="ml-1 rounded border px-1 py-0.5 text-xs">R</kbd>
											</TooltipContent>
										</Tooltip>

										<Tooltip>
											<TooltipTrigger asChild>
												<button
													className="hover:bg-background-bg-dim flex h-[20px] w-[20px] items-center justify-center gap-1 rounded-[5px] p-0 px-1"
													onClick={e => e.stopPropagation()}>
													<img src={forward} alt="Forward" className="h-[14px] w-[14px] flex-shrink-0" />
												</button>
											</TooltipTrigger>
											<TooltipContent>
												Forward <kbd className="ml-1 rounded border px-1 py-0.5 text-xs">F</kbd>
											</TooltipContent>
										</Tooltip>
									</div>
								</div>
							)}
							<div className="text-label-label-base text-body-head leading-body-head self-stretch whitespace-pre-wrap">
								{fullMessage || threadEmail.content}
							</div>
						</div>
					</div>
					<div className="border-background-bg-border w-full border-t-[0.5px]">
						{viewState.isReplying ? (
							<EmailReplyForm
								recipientName={getFirstName(threadEmail.to)}
								messageText={messageText}
								onMessageChange={onMessageChange}
								onSend={onSend}
								onDiscard={onDiscard}
								textareaRef={textareaRef}
							/>
						) : (
							<div className="bg-background-bg-base flex w-full items-center gap-3"></div>
						)}
					</div>
				</div>
			);
		},
	),
	(prevProps, nextProps) => {
		const arePropsEqual =
			prevProps.threadEmail.id === nextProps.threadEmail.id &&
			prevProps.isExpanded === nextProps.isExpanded &&
			prevProps.isActive === nextProps.isActive &&
			prevProps.isReplying === nextProps.isReplying &&
			prevProps.messageText === nextProps.messageText &&
			prevProps.isHovered === nextProps.isHovered &&
			prevProps.fullMessage === nextProps.fullMessage;
		return arePropsEqual;
	},
);

EmailThreadItem.displayName = "EmailThreadItem";
