export const letterDotPath = [
	{
		d: "M8.99261 2.33337H2.33325C2.00784 2.33337 1.70318 2.42219 1.44217 2.57691L6.54443 6.65871C6.81073 6.87176 7.18914 6.87176 7.45544 6.65871L9.28993 5.19113C8.94992 4.71414 8.74992 4.13044 8.74992 3.50004C8.74992 3.08523 8.83651 2.69064 8.99261 2.33337Z",
		fill: "#666666",
	},
	{
		d: "M9.90165 5.82229L8.64403 6.82839L13.2487 10.6656C13.3564 10.4386 13.4166 10.1847 13.4166 9.91671V5.83359C12.9291 6.19974 12.3232 6.41671 11.6666 6.41671C11.0033 6.41671 10.3917 6.19527 9.90165 5.82229Z",
		fill: "#666666",
	},
	{
		d: "M0.583252 4.08337C0.583252 3.758 0.672053 3.45336 0.826741 3.19237C0.846121 3.21622 0.868324 3.23838 0.893297 3.25835L5.35583 6.82838L0.751141 10.6656C0.643481 10.4386 0.583252 10.1847 0.583252 9.91671V4.08337Z",
		fill: "#666666",
	},
	{
		d: "M6.0544 7.38523C6.61724 7.79624 7.38261 7.79625 7.94546 7.38524L12.6883 11.3376C12.4008 11.5447 12.048 11.6667 11.6666 11.6667H2.33325C1.95188 11.6667 1.59901 11.5447 1.31152 11.3376L6.0544 7.38523Z",
		fill: "#666666",
	},
	{
		d: "M13.4167 3.5C13.4167 4.4665 12.6332 5.25 11.6667 5.25C10.7002 5.25 9.91675 4.4665 9.91675 3.5C9.91675 2.5335 10.7002 1.75 11.6667 1.75C12.6332 1.75 13.4167 2.5335 13.4167 3.5Z",
		fill: "#397AF1",
	},
];
