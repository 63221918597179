export const orderedListPath = [
	{
		d: "M3.80681 2.12887C3.97878 2.23516 4.08346 2.42291 4.08346 2.62508V5.54175C4.08346 5.86392 3.82229 6.12508 3.50013 6.12508C3.17796 6.12508 2.9168 5.86392 2.9168 5.54175V3.56894L2.59434 3.73017C2.30618 3.87424 1.95579 3.75744 1.81171 3.46929C1.66763 3.18114 1.78443 2.83074 2.07259 2.68666L3.23926 2.10333C3.42008 2.01292 3.63483 2.02258 3.80681 2.12887ZM7.00012 3.50008C6.678 3.50008 6.41678 3.76125 6.41678 4.08342C6.41678 4.40558 6.678 4.66675 7.00012 4.66675H11.6668C11.989 4.66675 12.2501 4.40558 12.2501 4.08342C12.2501 3.76125 11.989 3.50008 11.6668 3.50008H7.00012ZM7.00012 9.33342C6.678 9.33342 6.41678 9.59457 6.41678 9.91675C6.41678 10.2389 6.678 10.5001 7.00012 10.5001H11.6668C11.989 10.5001 12.2501 10.2389 12.2501 9.91675C12.2501 9.59457 11.989 9.33342 11.6668 9.33342H7.00012Z",
		fill: "#666666",
	},
	{
		d: "M2.83474 9.21261L2.83408 9.21313L2.83332 9.21372L2.83217 9.21459L2.83158 9.21506C2.57638 9.40843 2.2125 9.35996 2.01695 9.10574C1.82052 8.85042 1.86829 8.48414 2.12364 8.28773L2.15761 8.26271C2.17495 8.25034 2.19828 8.23418 2.22692 8.21557C2.28374 8.17859 2.36374 8.13029 2.46101 8.08193C2.64642 7.98971 2.93836 7.87508 3.27324 7.87508C4.04289 7.87508 4.66681 8.49902 4.66681 9.26867C4.66681 9.71509 4.47356 10.058 4.23933 10.3125C4.06081 10.5066 3.84393 10.6646 3.65465 10.7917H4.22931C4.55148 10.7917 4.81264 11.0529 4.81264 11.3751C4.81264 11.6973 4.55148 11.9584 4.22931 11.9584H2.33347C2.01131 11.9584 1.75014 11.6973 1.75014 11.3751C1.75014 10.9351 1.97882 10.6174 2.2043 10.4023C2.39535 10.2201 2.63725 10.0629 2.82744 9.93938L2.88928 9.89913C3.11368 9.75242 3.27275 9.64002 3.38075 9.52265C3.47464 9.42057 3.50014 9.34707 3.50014 9.26867C3.50014 9.14337 3.39855 9.04175 3.27324 9.04175C3.19336 9.04175 3.08833 9.07296 2.98047 9.12657C2.9311 9.15112 2.89038 9.17574 2.86316 9.19347C2.84976 9.20217 2.83976 9.20905 2.83474 9.21261Z",
		fill: "#666666",
	},
];
