import React from "react";
import { motion } from "framer-motion";

interface TextAnimationProps {
	company: string;
	content: React.ReactNode;
}

const COMPANY_COLORS: Record<string, string> = {
	"Electric AI": "#EA3368",
	"Aurora": "#832AD0",
	"Density": "#04082B",
	"AngelList": "#000000",
	"Ledge": "#3A845E",
	"Pod People": "#1C237B",
};

export default function TextAnimation({ company, content }: TextAnimationProps) {
	return (
		<div className="flex items-center justify-center text-sm">
			<motion.span
				initial={{ opacity: 0, y: 20 }}
				animate={{ opacity: 1, y: 0 }}
				exit={{ opacity: 0, y: -20 }}
				transition={{ duration: 0.5 }}
				className="flex items-center gap-x-1">
				<span style={{ color: COMPANY_COLORS[company] }}>{company}</span>
				<span className="text-[#666666]">{content}</span>
			</motion.span>
		</div>
	);
}
