import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "@/lib/utils";

const buttonVariants = cva(
	"inline-flex items-center justify-center gap-[6px] whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 [&_svg]:pointer-events-none [&_svg]:size-4 [&_svg]:shrink-0 h-8",
	{
		variants: {
			variant: {
				default: [
					"bg-button-primary bg-button-primary-p3",
					"text-white",
					"rounded-[6px]",
					"shadow-low",
					"hover:opacity-90",
				].join(" "),
				destructive: "bg-destructive text-destructive-foreground hover:bg-destructive/90",
				outline: "border border-input bg-background hover:bg-accent hover:text-accent-foreground",
				secondary: [
					"h-8 px-[10px] gap-[6px] flex-shrink-0",
					"rounded-lg border-[0.5px] border-[--background-bg-border]",
					"bg-[--background-bg-base]",
					"shadow-low",
					"text-[13px] font-medium leading-[18px]",
					"text-[--label-label-base]",
					"hover:bg-[--background-bg-hover] hover:border-[--background-bg-border-hover]",
				].join(" "),
				ghost: "hover:bg-accent hover:text-accent-foreground",
				link: "text-primary underline-offset-4 hover:underline",
				exit: [
					"inline-flex h-8 items-center justify-center gap-[6px] px-[10px] flex-shrink-0",
					"rounded-lg",
					"text-[13px] font-medium leading-[18px]",
					"text-[--label-label-muted]",
					"cursor-pointer",
					"hover:bg-[--background-bg-hover]",
				].join(" "),
				inboxAction: [
					"flex w-6 h-6 px-1 justify-center items-center gap-1",
					"rounded-[5px]",
					"[&_svg]:size-3.5 [&_svg]:flex-shrink-0",
					"hover:bg-background-bg-hover hover:shadow-low",
				].join(" "),
			},
			size: {
				default: "px-4 py-2",
				sm: "rounded-md px-3",
				lg: "rounded-md px-8",
				icon: "w-10",
			},
		},
		defaultVariants: {
			variant: "default",
			size: "default",
		},
	},
);

export interface ButtonProps
	extends React.ButtonHTMLAttributes<HTMLButtonElement>,
		VariantProps<typeof buttonVariants> {
	asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
	({ className, variant, size, asChild = false, ...props }, ref) => {
		const Comp = asChild ? Slot : "button";
		return <Comp className={cn(buttonVariants({ variant, size, className }))} ref={ref} {...props} />;
	},
);
Button.displayName = "Button";

export { Button, buttonVariants };
