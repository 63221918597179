'use client'

import { UnderConstruction } from '@/components';
import React from 'react';

const Sent = () => {
    return (
        <div className=' w-full'>
            <UnderConstruction/>
        </div>
    );
};

export default Sent;
