import { Button } from "@/components/ui/button";
import { SignInButton, SignUpButton } from "@clerk/clerk-react";
import BlueLogo from "../../assets/blue-logo.svg?react";
import { useEffect, useState } from "react";
import TextAnimation from "@/components/TextAnimation/TextAnimation";
import { AnimatePresence } from "framer-motion";

const TESTIMONIALS = [
	{
		company: "Electric AI",
		content: (
			<>
				is booking <span className="font-semibold text-[#171717]">3x more meetings</span> every week
			</>
		),
	},
	{
		company: "Density",
		content: (
			<>
				saw <span className="font-semibold text-[#171717]">positive replies jump 52%</span>
			</>
		),
	},
	{
		company: "Aurora",
		content: (
			<>
				has won clients <span className="font-semibold text-[#171717]">over $400m in pipeline</span>
			</>
		),
	},
	{
		company: "AngelList",
		content: (
			<>
				<span className="font-semibold text-[#171717]">doubled their open rate</span>
			</>
		),
	},
	{
		company: "Ledge",
		content: (
			<>
				<span className="font-semibold text-[#171717]">booked 31 demos</span> in their first 60 days
			</>
		),
	},
	{
		company: "Pod People",
		content: (
			<>
				<span className="font-semibold text-[#171717]">quadrupled outbound with no new hires</span>
			</>
		),
	},
];

export default function Page() {
	const [currentIndex, setCurrentIndex] = useState(0);

	useEffect(() => {
		const interval = setInterval(() => {
			setCurrentIndex(current => (current + 1) % TESTIMONIALS.length);
		}, 5000);

		return () => clearInterval(interval);
	}, []);

	return (
		<div className="bg-background-bg-dim flex min-h-screen flex-col">
			<div className="flex flex-1 flex-col items-center justify-center">
				<div className="flex flex-col items-center space-y-8">
					<BlueLogo className="text-brand-primary h-[24px] w-[136px]" />
					<div className="border-background-bg-border bg-background-bg-base shadow-low mx-auto w-full max-w-sm rounded-[8px] border-[0.5px] p-10 text-center">
						<h1 className="mb-8 text-2xl font-bold text-gray-800">Your first campaign is just a sign-up away.</h1>
						<div className="flex flex-col items-center justify-center space-y-2">
							<SignUpButton mode="modal">
								<Button variant="default" className="bg-brand-primary hover:bg-brand-primary/90 w-full text-white">
									Sign up
								</Button>
							</SignUpButton>
							<SignInButton mode="modal">
								<Button variant="default" className="w-full bg-[#3A3940] text-white hover:bg-[#3A3940]/90">
									Sign in
								</Button>
							</SignInButton>
						</div>
						<p className="text-muted-foreground mt-8 text-sm">
							By joining, you agree to our{" "}
							<a href="https://za-zu.com/terms-of-service" className="text-gray-900 hover:underline">
								Terms of Service
							</a>{" "}
							and{" "}
							<a href="https://za-zu.com/privacy-policy" className="text-gray-900 hover:underline">
								Privacy Policy
							</a>
							.
						</p>
					</div>
					<div>
						<AnimatePresence mode="wait">
							<TextAnimation
								key={currentIndex}
								company={TESTIMONIALS[currentIndex].company}
								content={TESTIMONIALS[currentIndex].content}
							/>
						</AnimatePresence>
					</div>
				</div>
			</div>
		</div>
	);
}
