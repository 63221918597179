import React, { useEffect, useState } from "react";
import { InboxHeader } from "@/components";
import InboxList from "@/components/InboxList";
import { useGlobalFilterStore, useInboxStore } from "@/store";
import { InboxTabs } from "@/app/enums/inbox.enum";
import { Email } from "@/types/inbox";
import { filterData } from "@/utils/filterData";

const Inbox = () => {
	const { primary, sent, done, fetchInbox } = useInboxStore();
	const { searchText, setSearchText } = useGlobalFilterStore();
	const [sortOrder, setSortOrder] = useState<string>("Newest");
	const [activeTab, setActiveTab] = useState(InboxTabs.PRIMARY);
	const [emails, setEmails] = useState<Email[]>([]);
	const [filteredEmails, setFilteredEmails] = useState<Email[]>([]);
	const [isModalOpen, setIsModalOpen] = useState(false);

	useEffect(() => {
		if (primary.length === 0) {
			fetchInbox(); // fetching mocked data
		}

		if (activeTab === InboxTabs.PRIMARY) {
			setEmails(primary);
			setFilteredEmails(primary);
		} else if (activeTab === InboxTabs.SENT) {
			setEmails(sent);
			setFilteredEmails(sent);
		} else if (activeTab === InboxTabs.DONE) {
			setEmails(done);
			setFilteredEmails(done);
		}
	}, [primary, sent, done, activeTab]);

	useEffect(() => {
		const sortedEmails = emails.map(email => ({
			...email,
			timestamp: new Date(email.timestamp),
		}));
		if (sortedEmails.length === 0) return;

		if (sortOrder === "Newest") {
			sortedEmails.sort((a, b) => b.timestamp.getTime() - a.timestamp.getTime());
		} else {
			sortedEmails.sort((a, b) => a.timestamp.getTime() - b.timestamp.getTime());
		}

		const filteredAndSortedEmails = filterData(sortedEmails, searchText, (email, searchText) => {
			return (
				email.name.toLowerCase().includes(searchText.toLowerCase()) ||
				email.campaignTitle.toLowerCase().includes(searchText.toLowerCase()) ||
				email.messagePreview.toLowerCase().includes(searchText.toLowerCase())
			);
		});

		setFilteredEmails(filteredAndSortedEmails);
	}, [emails, searchText, sortOrder]);

	return (
		<div className="flex h-full w-full flex-col">
			<InboxHeader
				activeTab={activeTab}
				setActiveTab={setActiveTab}
				primaryCount={primary.length}
				searchEmail={searchText}
				setSearchEmail={setSearchText}
				sortOrder={sortOrder}
				setSortOrder={setSortOrder}
				isModalOpen={isModalOpen}
			/>
			<InboxList activeTab={activeTab} emails={filteredEmails} onModalStateChange={setIsModalOpen} />
		</div>
	);
};

export default Inbox;
