export const letterCheckPath = [
	{
		d: "M13.2457 1.33748C13.4735 1.56529 13.4735 1.93463 13.2457 2.16244L10.9124 4.49577C10.6846 4.72358 10.3152 4.72358 10.0874 4.49577L8.92077 3.32911C8.69297 3.1013 8.69297 2.73195 8.92077 2.50415C9.14858 2.27634 9.51793 2.27634 9.74573 2.50415L10.4999 3.25833L12.4208 1.33748C12.6486 1.10967 13.0179 1.10967 13.2457 1.33748Z",
		fill: "#1A1A1A",
	},
	{
		d: "M8.92077 3.32911L10.0874 4.49577C10.0982 4.50652 10.1093 4.51676 10.1206 4.5265L12.5577 2.57684C12.2967 2.42211 11.992 2.33329 11.6666 2.33329H11.425L10.4999 3.25833L9.74573 2.50415C9.51793 2.27634 9.14858 2.27634 8.92077 2.50415C8.69297 2.73195 8.69297 3.1013 8.92077 3.32911Z",
		fill: "#1A1A1A",
	},
	{
		d: "M9.20263 5.26088L8.09582 4.15406C7.60344 3.66168 7.4658 2.9489 7.68291 2.33329H2.33325C2.00784 2.33329 1.70318 2.42211 1.44217 2.57683L6.54443 6.65863C6.81073 6.87167 7.18914 6.87167 7.45544 6.65863L9.20263 5.26088Z",
		fill: "#1A1A1A",
	},
	{
		d: "M11.7374 5.32073C11.263 5.79513 10.584 5.94022 9.98441 5.75601L8.64403 6.82831L13.2487 10.6655C13.3564 10.4385 13.4166 10.1846 13.4166 9.91663V4.08329C13.4166 3.94687 13.401 3.81409 13.3714 3.68664L11.7374 5.32073Z",
		fill: "#1A1A1A",
	},
	{
		d: "M0.583252 4.08329C0.583252 3.75791 0.672053 3.45328 0.826741 3.19229C0.846121 3.21614 0.868324 3.23829 0.893297 3.25827L5.35583 6.8283L0.751141 10.6655C0.643481 10.4385 0.583252 10.1846 0.583252 9.91663V4.08329Z",
		fill: "#1A1A1A",
	},
	{
		d: "M6.0544 7.38515C6.61724 7.79616 7.38261 7.79617 7.94546 7.38516L12.6883 11.3375C12.4008 11.5446 12.048 11.6666 11.6666 11.6666H2.33325C1.95188 11.6666 1.59901 11.5446 1.31152 11.3375L6.0544 7.38515Z",
		fill: "#1A1A1A",
	},
];
