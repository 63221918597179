import { create } from "zustand";

type Client = {
    id: string;
    name: string;
};

type UseClientStoreProps = {
    clients: Client[];
    getClients: () => Promise<Client[]>;
};

const useClientStore = create<UseClientStoreProps>((set) => ({
    clients: [],
    getClients: async () => {
        // here we should do the API call..
        const mockClients: Client[] = [
            { id: 'client-1', name: 'Client A' },
            { id: 'client-2', name: 'Client B' },
            { id: 'client-3', name: 'Client C' },
        ];
        set({ clients: mockClients });
        return mockClients;
    },
}));

export default useClientStore;
