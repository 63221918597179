import React, { useEffect } from "react";
import { Link } from "wouter";
import { MenuItem } from "@/types/globals";
import { useGlobalFilterStore } from "@/store";
import { OrganizationSwitcher, useAuth, useOrganization } from "@clerk/clerk-react";
import { usePathname } from "wouter/use-browser-location";
import { june } from "@/utils/juneAnalytics";
import SearchBar from "@/components/SearchBar";
import IconSVG from "@/components/IconSVG/IconSVG";
import {
	Sidebar,
	SidebarContent,
	SidebarHeader,
	SidebarMenu,
	SidebarMenuButton,
	SidebarSeparator,
	SidebarFooter,
	SidebarMenuItem,
} from "@/components/ui/sidebar";
import { cn } from "@/lib/utils";
import { Tooltip, TooltipTrigger, TooltipContent } from "@/components/ui/tooltip";
import { Popover, PopoverTrigger, PopoverContent } from "@/components/ui/popover";
import { Textarea } from "@/components/ui/textarea";
import { Button } from "@/components/ui/button";
import { InboxIcon } from "@/assets/icons/components/InboxIcon";
import {
	fileTextPath,
	emailPath,
	analyticsPath,
	usersPath,
	supportPath,
	feedbackPath,
	canaryPath,
	flaskPath,
} from "@/assets/icons/paths";
import UserProfile from "@/components/User/user-profile";
import { Spinner } from "../ui/spinner";
import { Input } from "../ui/input";
import clsx from "clsx";

const sidebarMenuItems: MenuItem[] = [
	{
		icon: <IconSVG path={fileTextPath} width={16} height={16} viewBox="0 0 16 16" />,
		label: "Campaigns",
		value: "campaigns",
	},
	{
		icon: <IconSVG path={emailPath} width={16} height={16} viewBox="0 0 16 16" />,
		label: "Email Accounts",
		value: "email-accounts",
	},
	{
		icon: <InboxIcon />,
		label: "Inbox (6)",
		value: "inbox",
	},
	// {
	// 	icon: <IconSVG path={analyticsPath} width={16} height={16} viewBox="0 0 16 16" />,
	// 	label: "Global Analytics",
	// 	value: "global-analytics",
	// },
	// {
	// 	icon: <IconSVG path={usersPath} width={16} height={16} viewBox="0 0 16 16" />,
	// 	label: "All Leads",
	// 	value: "all-leads",
	// },
];

const DevToolsPopover = () => {
	const { getToken } = useAuth();

	const [loadingToken, setLoadingToken] = React.useState(false);
	const [copiedToken, setCopiedToken] = React.useState(false);
	const [cachedToken, setToken] = React.useState("");

	const { organization } = useOrganization();
	const [copiedOrgId, setCopiedOrgId] = React.useState(false);

	return (
		<div className="space-y-4">
			<h1>Dev utils</h1>
			<div className="flex flex-col gap-2 rounded-md bg-gray-100 p-2">
				<h2 className="text-label-muted cursor-default">Generate a long-lived Clerk Authorization token (7 days)</h2>
				<Button
					size="sm"
					onClick={async () => {
						setLoadingToken(true);
						const token = cachedToken ? cachedToken : await getToken({ template: "api-testing" });
						if (token) {
							navigator.clipboard.writeText(token);
							setCopiedToken(true);
							setToken(token);
						}
						setLoadingToken(false);
						setTimeout(() => {
							setCopiedToken(false);
						}, 1500);
					}}
					disabled={loadingToken}
					className={clsx("bg-brand-primary hover:bg-brand-primary/90", loadingToken && "opacity-50")}>
					{loadingToken ? <Spinner size="sm" /> : copiedToken ? "Copied!" : "Generate and copy"}
				</Button>
			</div>
			<div className="flex flex-col gap-2 rounded-md bg-gray-100 p-2">
				<h2 className="text-label-muted cursor-default">Current Organization ID</h2>
				<Input
					value={organization?.id}
					className="text-muted-foreground"
					aria-disabled
					onChange={e => e.preventDefault()}
					onClick={e => (e.target as HTMLInputElement).select()}
				/>
				<Button
					size="sm"
					onClick={async () => {
						if (organization?.id) navigator.clipboard.writeText(organization.id);
						setCopiedOrgId(true);
						setTimeout(() => {
							setCopiedOrgId(false);
						}, 1500);
					}}
					className="bg-brand-primary hover:bg-brand-primary/90">
					{!organization ? <Spinner size="sm" /> : copiedOrgId ? "Copied!" : "Copy"}
				</Button>
			</div>
		</div>
	);
};

const NewSidebar: React.FC = () => {
	const { searchText, setSearchText } = useGlobalFilterStore();
	const currentPath = usePathname();
	const [feedbackOpen, setFeedbackOpen] = React.useState(false);
	const [feedbackText, setFeedbackText] = React.useState("");

	const [devUtilsOpen, setDevUtilsOpen] = React.useState(false);

	const handleSendFeedback = () => {
		// TODO: Implement feedback submission
		// Could be an API call like:
		// await submitFeedback(feedbackText);

		setFeedbackText(""); // Clear the input
		setFeedbackOpen(false); // Close the popover
	};

	const handleKeyDown = (e: React.KeyboardEvent) => {
		if ((e.metaKey || e.ctrlKey) && e.key === "Enter") {
			e.preventDefault();
			if (feedbackText.trim()) {
				handleSendFeedback();
			}
		}
	};

	useEffect(() => {
		setSearchText("");
	}, [currentPath]);

	return (
		<Sidebar collapsible="icon">
			<div className={cn("flex h-full flex-col", "space-y-6", "px-5", "group-data-[collapsible=icon]:px-2")}>
				<SidebarHeader className={cn("flex-col gap-4", "group-data-[collapsible=icon]:items-center")}>
					<div className="group-data-[collapsible=icon]:hidden">
						<OrganizationSwitcher
							hidePersonal
							appearance={{
								elements: {
									rootBox: "w-full",
									organizationSwitcherTrigger:
										"w-full flex items-center justify-start gap-2 py-1 pl-0 text-[#1A1A1A]  text-[13px] font-medium leading-[18px]",
									organizationPreview: "gap-2",
									organizationPreviewAvatarBox: "w-7 h-7",
									organizationSwitcherTriggerIcon: "m-0",
								},
								layout: {
									shimmer: false,
								},
								variables: {
									colorPrimary: "#333",
								},
							}}
						/>
					</div>
					<div className="w-full max-w-[234px] group-data-[collapsible=icon]:hidden">
						<SearchBar searchText={searchText} setSearchText={setSearchText} />
					</div>
				</SidebarHeader>

				<SidebarContent className="flex-1">
					<div className="space-y-4">
						<SidebarMenu>
							{sidebarMenuItems.map((item, index) => {
								if (item.value === "spacer") {
									return <SidebarSeparator key={`spacer-${index}`} />;
								}

								const isActive = currentPath === `/dashboard/${item.value}`;
								const iconWithActiveState = React.cloneElement(item.icon as React.ReactElement, {
									isActive,
								});

								return (
									<SidebarMenuItem key={item.value}>
										<Tooltip>
											<TooltipTrigger asChild>
												<SidebarMenuButton asChild>
													<Link
														href={`/dashboard/${item.value}`}
														onClick={() => june.track("open_page", { page: item.value })}
														className={cn(
															"relative flex h-8 items-center gap-2 rounded-[6px] px-2",
															"group-data-[collapsible=icon]:w-8 group-data-[collapsible=icon]:justify-center",
															isActive
																? "isolate border-[0.5px] border-[color(display-p3_0.851_0.851_0.851)] !bg-[#FFFFFF] shadow-[0px_1px_1px_0px_color(display-p3_0_0_0/0.06),0px_4px_4px_-1px_color(display-p3_0_0_0/0.02)]"
																: "",
														)}>
														{iconWithActiveState}
														<span className="group-data-[collapsible=icon]:hidden">{item.label}</span>
													</Link>
												</SidebarMenuButton>
											</TooltipTrigger>
											<TooltipContent side="right" className="group-data-[state=expanded]:hidden">
												{item.label}
											</TooltipContent>
										</Tooltip>
									</SidebarMenuItem>
								);
							})}
						</SidebarMenu>
					</div>
				</SidebarContent>

				<div className="list-none space-y-1">
					{import.meta.env.VITE_ENV === "development" && (
						<SidebarMenuItem>
							<Popover open={devUtilsOpen} onOpenChange={setDevUtilsOpen}>
								<Tooltip>
									<TooltipTrigger asChild>
										<PopoverTrigger asChild>
											<SidebarMenuButton asChild size="md">
												<div
													role="button"
													tabIndex={0}
													onClick={() => {
														june.track("open_dev_utils");
														setDevUtilsOpen(true);
													}}
													onKeyDown={e => {
														if (e.key === "Enter" || e.key === " ") {
															e.preventDefault();
															june.track("open_dev_utils");
															setDevUtilsOpen(true);
														}
													}}
													className={cn(
														"relative flex h-8 items-center gap-2 rounded-[6px] px-2",
														"group-data-[collapsible=icon]:w-8 group-data-[collapsible=icon]:justify-center",
														"cursor-pointer",
													)}>
													<IconSVG
														path={flaskPath}
														width={16}
														height={16}
														viewBox="0 0 256 256"
														useStroke={false}
														className="text-label-faint shrink-0 stroke-current"
													/>
													<span className="group-data-[collapsible=icon]:hidden">Dev utils</span>
												</div>
											</SidebarMenuButton>
										</PopoverTrigger>
									</TooltipTrigger>
									<TooltipContent side="right" className="group-data-[state=expanded]:hidden">
										Dev utils
									</TooltipContent>
								</Tooltip>
								<PopoverContent className="w-[274px] p-4" side="top">
									<DevToolsPopover />
								</PopoverContent>
							</Popover>
						</SidebarMenuItem>
					)}

					<SidebarMenuItem>
						<Tooltip>
							<TooltipTrigger asChild>
								<SidebarMenuButton asChild size="md">
									<Link
										href="/support"
										onClick={() => june.track("open_support")}
										className={cn(
											"relative flex h-8 items-center gap-2 rounded-[6px] px-2",
											"group-data-[collapsible=icon]:w-8 group-data-[collapsible=icon]:justify-center",
										)}>
										<IconSVG
											path={supportPath}
											width={16}
											height={16}
											viewBox="0 0 16 16"
											className="text-label-faint"
										/>
										<span className="group-data-[collapsible=icon]:hidden">Support</span>
									</Link>
								</SidebarMenuButton>
							</TooltipTrigger>
							<TooltipContent side="right" className="group-data-[state=expanded]:hidden">
								Support
							</TooltipContent>
						</Tooltip>
					</SidebarMenuItem>

					<SidebarMenuItem>
						<Tooltip>
							<TooltipTrigger asChild>
								<SidebarMenuButton asChild size="md">
									<Link
										href="/forum"
										onClick={() => june.track("open_forum")}
										className={cn(
											"relative flex h-8 items-center gap-2 rounded-[6px] px-2",
											"group-data-[collapsible=icon]:w-8 group-data-[collapsible=icon]:justify-center",
										)}>
										<IconSVG
											path={canaryPath}
											width={16}
											height={16}
											viewBox="0 0 16 16"
											className="text-label-faint"
										/>
										<span className="group-data-[collapsible=icon]:hidden">Community forum</span>
									</Link>
								</SidebarMenuButton>
							</TooltipTrigger>
							<TooltipContent side="right" className="group-data-[state=expanded]:hidden">
								Join our forum where you can hang out with fellow za-zu users and ask them questions. It&apos;s like an
								AI but with people.
							</TooltipContent>
						</Tooltip>
					</SidebarMenuItem>

					<SidebarMenuItem>
						<Popover open={feedbackOpen} onOpenChange={setFeedbackOpen}>
							<Tooltip>
								<TooltipTrigger asChild>
									<PopoverTrigger asChild>
										<SidebarMenuButton asChild size="md">
											<div
												role="button"
												tabIndex={0}
												onClick={() => {
													june.track("open_feedback");
													setFeedbackOpen(true);
												}}
												onKeyDown={e => {
													if (e.key === "Enter" || e.key === " ") {
														e.preventDefault();
														june.track("open_feedback");
														setFeedbackOpen(true);
													}
												}}
												className={cn(
													"relative flex h-8 items-center gap-2 rounded-[6px] px-2",
													"group-data-[collapsible=icon]:w-8 group-data-[collapsible=icon]:justify-center",
													"cursor-pointer",
												)}>
												<IconSVG
													path={feedbackPath}
													width={16}
													height={16}
													viewBox="0 0 16 16"
													useStroke={true}
													className="text-label-faint shrink-0"
												/>
												<span className="group-data-[collapsible=icon]:hidden">Share feedback</span>
											</div>
										</SidebarMenuButton>
									</PopoverTrigger>
								</TooltipTrigger>
								<TooltipContent side="right" className="group-data-[state=expanded]:hidden">
									Share feedback
								</TooltipContent>
							</Tooltip>
							<PopoverContent className="w-[274px] p-4" side="top">
								<div className="space-y-4">
									<Textarea
										placeholder="Help us improve this page."
										className="focus-visible:ring-brand-primary min-h-[100px] resize-none"
										value={feedbackText}
										onChange={e => setFeedbackText(e.target.value)}
										onKeyDown={handleKeyDown}
									/>
									<div className="flex justify-between gap-2">
										<Button
											variant="outline"
											size="sm"
											onClick={() => {
												setFeedbackText("");
												setFeedbackOpen(false);
											}}
											className="">
											Cancel
										</Button>
										<Button
											size="sm"
											onClick={handleSendFeedback}
											disabled={!feedbackText.trim()}
											className="bg-brand-primary hover:bg-brand-primary/90">
											Send feedback (⌘↵)
										</Button>
									</div>
									<p className="text-muted-foreground text-xs">
										Have a specific issue?{" "}
										<a href="/support" className="text-brand-primary hover:text-brand-primary/90 hover:underline">
											Contact support
										</a>{" "}
										or{" "}
										<a href="/docs" className="text-brand-primary hover:text-brand-primary/90 hover:underline">
											read our docs
										</a>
										.
									</p>
								</div>
							</PopoverContent>
						</Popover>
					</SidebarMenuItem>
				</div>

				<SidebarFooter className={cn("mt-auto", "group-data-[collapsible=icon]:items-center")}>
					<UserProfile />
				</SidebarFooter>
			</div>
		</Sidebar>
	);
};

export default NewSidebar;
