export const formatTimestamp = (timestamp: string | Date, expanded = false): string => {
	const date = new Date(timestamp);
	const now = new Date();
	const isToday = date.toDateString() === now.toDateString();
	const isThisYear = date.getFullYear() === now.getFullYear();

	if (expanded) {
		return `${date
			.toLocaleDateString("en-US", {
				month: "short",
				day: "numeric",
			})
			.replace(",", "")} ${date.toLocaleTimeString("en-US", {
			hour: "numeric",
			minute: "2-digit",
			hour12: true,
		})}`;
	}

	if (isToday) {
		return date.toLocaleTimeString("en-US", {
			hour: "numeric",
			minute: "2-digit",
			hour12: true,
		});
	}

	if (isThisYear) {
		return date.toLocaleDateString("en-US", {
			month: "short",
			day: "numeric",
		});
	}

	const month = (date.getMonth() + 1).toString();
	const day = date.getDate().toString();
	const year = date.getFullYear().toString().slice(-2);

	return `${month}/${day}/${year}`;
};
