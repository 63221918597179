import React from "react";

interface IconSVGProps {
	path: string | string[] | Array<{ d: string; fill?: string }>;
	width?: number;
	height?: number;
	viewBox?: string;
	isActive?: boolean;
	fill?: string;
	stroke?: string;
	className?: string;
	useStroke?: boolean;
}

const IconSVG: React.FC<IconSVGProps> = ({
	path,
	width = 16,
	height = 16,
	viewBox = "0 0 14 14",
	isActive = false,
	fill,
	className,
	useStroke = false,
}) => {
	const color = isActive ? "#1A1A1A" : "#AAAAAA";
	const paths = Array.isArray(path) ? path.map(p => (typeof p === "string" ? { d: p } : p)) : [{ d: path as string }];

	return (
		<svg
			width={width}
			height={height}
			viewBox={viewBox}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={className}>
			{paths.map((p, index) => (
				<path
					key={index}
					d={p.d}
					fillRule={useStroke ? undefined : "evenodd"}
					clipRule={useStroke ? undefined : "evenodd"}
					fill={useStroke ? "none" : p.fill || fill || color}
					stroke={useStroke ? "currentColor" : undefined}
					strokeWidth={useStroke ? 1.5 : undefined}
					strokeLinecap={useStroke ? "round" : undefined}
					strokeLinejoin={useStroke ? "round" : undefined}
				/>
			))}
		</svg>
	);
};

export default IconSVG;
