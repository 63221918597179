import { create } from "zustand";
import { persist } from "zustand/middleware";
import { generateCampaignData } from "@/utils/generators";
import { CampaignProps } from "@/types/campaign";
import { CampaignCreationStepEnum, CampaignStatusEnum } from "@/app/enums/campaign.enum";
import { toast } from "sonner";

const emptyNewCampaign: CampaignProps = {
	id: "",
	title: "",
	status: CampaignStatusEnum.Draft,
	activeStepId: "",
	sequence: [
		{
			id: "seq-" + new Date().getTime(),
			order: 0,
			subject: "",
			html: "",
			delayInDays: 3,
		},
	],
	client_id: "",
	workspace_id: "",
	created_by_user_id: "",
	date_created: "",
	date_updated: "",
	mailbox_ids: [],
	custom_field_requirements: {},
	campaignMetrics: {
		sent: 0,
		clicked: 0,
		clickedPct: "0%",
		opened: 0,
		openedPct: "0%",
		replied: 0,
		repliedPct: "0%",
		positiveReplies: 0,
		positiveRepliesPct: "0%",
	},
};

type useCampaignStoreProps = {
	campaigns: CampaignProps[]; // Ensure this is not (CampaignProps | null)[]
	selectedCampaign: CampaignProps | null;
	activeCampaignStep: CampaignCreationStepEnum;
	previewMode: boolean;
	defaultTestEmail: string;
	setSelectedCampaign: (value: CampaignProps | null) => void;
	setDefaultTestEmail: (value: string) => void;
	setPreviewMode: (value: boolean) => void;
	setActiveCampaignStep: (value: CampaignCreationStepEnum) => void;
	getCampaigns: (generateNew?: boolean) => Promise<CampaignProps[]>;
	createNewCampaign: (title?: string) => void;
	saveSelectedToCampaigns: () => void;
	updateHtmlInActiveStep: (newHtml: string) => void;
	setCampaigns: (campaigns: CampaignProps[]) => void;
};

const getNextCampaignNumber = (campaigns: CampaignProps[]): number => {
	// If there are no campaigns, start with 1
	if (!campaigns.length) return 1;

	// Get the highest campaign number by looking at all campaigns
	const existingNumbers = campaigns.map((_, index) => index + 1);

	return Math.max(...existingNumbers) + 1;
};

const useCampaignStore = create<useCampaignStoreProps>()(
	persist(
		(set, get) => ({
			campaigns: [],
			selectedCampaign: null,
			activeCampaignStep: CampaignCreationStepEnum.ImportLeads,
			previewMode: false,
			defaultTestEmail: "",
			setSelectedCampaign: value => {
				set({ selectedCampaign: value });
			},
			setDefaultTestEmail: value => set({ defaultTestEmail: value }),
			setPreviewMode: value => set({ previewMode: value }),
			setActiveCampaignStep: value => set({ activeCampaignStep: value }),

			getCampaigns: async (generateNew = true) => {
				if (generateNew) {
					const newCampaigns = await generateCampaignData(10);
					set({ campaigns: newCampaigns });
					return newCampaigns;
				} else {
					const emptyCampaigns: CampaignProps[] = [];
					set({ campaigns: emptyCampaigns });
					return emptyCampaigns;
				}
			},
			createNewCampaign: (title?: string) => {
				const nextNumber = getNextCampaignNumber(get().campaigns);
				const newCampaign: CampaignProps = {
					...emptyNewCampaign,
					id: crypto.randomUUID(),
					title: title || `Campaign ${nextNumber}`,
				};

				set(state => ({
					...state,
					campaigns: [newCampaign, ...state.campaigns],
					selectedCampaign: newCampaign,
				}));
			},
			saveSelectedToCampaigns: () => {
				set(state => {
					const { selectedCampaign, campaigns } = state;
					if (selectedCampaign) {
						const updatedCampaigns = campaigns.map(campaign =>
							campaign.id === selectedCampaign.id ? selectedCampaign : campaign,
						);
						toast.success("Campaign has been saved");
						return { campaigns: updatedCampaigns };
					}
					return {}; // Return an empty object if no changes are made
				});
			},
			updateHtmlInActiveStep: (newHtml: string) => {
				const { selectedCampaign } = get();
				if (selectedCampaign && selectedCampaign.activeStepId) {
					const updatedSequence = selectedCampaign.sequence.map(step => {
						if (step.id === selectedCampaign.activeStepId) {
							return { ...step, html: newHtml };
						}
						return step;
					});
					set({
						selectedCampaign: { ...selectedCampaign, sequence: updatedSequence },
					});
				}
			},
			setCampaigns: campaigns => {
				set({
					campaigns,
					selectedCampaign: null, // Also clear selected campaign when clearing all campaigns
				});
			},
		}),
		{
			name: "campaigns-storage",
		},
	),
);

export default useCampaignStore;
