import React, { useState, useRef, useEffect, useMemo } from "react";
import { Dialog } from "@headlessui/react";
import { Email, ThreadEmail } from "@/types/inbox";
import { Button } from "../ui/button";
import chevronSmallUp from "@/assets/icons/images/chevron-small-up.png";
import arrowLeft from "@/assets/icons/images/arrow-left.png";
import doubleCheckmarkBlack from "@/assets/icons/images/double-checkmark-black.png";
import unread from "@/assets/icons/images/unread.png";
import IconSVG from "../IconSVG/IconSVG";
import { Tooltip, TooltipTrigger, TooltipContent } from "../ui/tooltip";
import { TooltipProvider } from "../ui/tooltip";
import { fileTextPath } from "@/assets/icons/paths/file-text";
import { EmailThreadItem } from "./EmailThreadItem";

interface EmailModalProps {
	isOpen: boolean;
	onClose: () => Promise<void>;
	email: Email | null;
	onNavigateThread: (direction: "up" | "down") => void;
}

const ME = { name: "Me", email: "me@za-zu.com" };

interface EmailState {
	expandedIds: Set<number>;
	activeId: number | null;
	replyingToId: number | null;
	isUnread: boolean;
	messageText: string;
	isLoading: boolean;
	hoveredId: number | null;
}

interface EmailHandlers {
	onClose: () => void;
	navigateThread: (direction: "up" | "down") => void;
	handleSend: (markAsDone?: boolean) => void;
	handleStartReply: (id: number) => void;
	handleEmailClick: (id: number) => void;
	setUnread: (value: boolean) => void;
}

const EmailModal: React.FC<EmailModalProps> = ({ isOpen, onClose, email, onNavigateThread }) => {
	const isInitialLoad = useRef(true);

	const [emailState, setEmailState] = useState<EmailState>({
		expandedIds: new Set(),
		activeId: null,

		replyingToId: null,
		isUnread: false,
		messageText: "",
		isLoading: false,
		hoveredId: null,
	});

	const textareaRef = useRef<HTMLTextAreaElement | null>(null);
	const [sendButtonRef, setSendButtonRef] = useState<HTMLButtonElement | null>(null);
	const [sendMarkDoneRef, setSendMarkDoneRef] = useState<HTMLButtonElement | null>(null);

	// Initialize emailRefs with React refs
	const emailRefs = useRef<{ [key: number]: React.RefObject<HTMLDivElement> }>({});

	// Update state helpers
	const updateEmailState = (updates: Partial<EmailState>) => {
		setEmailState(current => ({ ...current, ...updates }));
	};

	// Convert existing handlers to use new state
	const handlers: EmailHandlers = {
		onClose,
		navigateThread: direction => {
			onNavigateThread(direction);
		},
		handleSend: async (markAsDone = false) => {
			if (!email || !emailState.messageText.trim()) return;

			try {
				updateEmailState({ isLoading: true });
				const newEmail: ThreadEmail = {
					id: email.thread.length + 1,
					from: ME.name,
					to: email.thread[0].from,
					fromEmail: ME.email,
					toEmail: email.thread[0].fromEmail,
					content: emailState.messageText,
					timestamp: new Date().toISOString(),
				};

				email.thread.push(newEmail);
				updateEmailState({
					messageText: "",
					replyingToId: null,
				});

				if (markAsDone) {
					await onClose();
				}
			} finally {
				updateEmailState({ isLoading: false });
			}
		},
		handleStartReply: id => {
			if (emailState.replyingToId === id) return; // Prevent duplicate opens

			if (!emailState.expandedIds.has(id)) {
				// Ensure email is expanded before replying
				const newExpandedIds = new Set(emailState.expandedIds);
				newExpandedIds.add(id);
				updateEmailState({
					expandedIds: newExpandedIds,
					activeId: id,
					replyingToId: id,
				});
			} else {
				updateEmailState({ replyingToId: id });
			}
		},
		handleEmailClick: id => {
			setEmailState(current => {
				const expandedIds = new Set(current.expandedIds);
				if (!expandedIds.has(id)) {
					// Expand the item if it's not already expanded
					expandedIds.add(id);
				}
				// Set the clicked item as active without collapsing it
				return {
					...current,
					expandedIds,
					activeId: id,
				};
			});
		},
		setUnread: value => {
			updateEmailState({ isUnread: value });
		},
	};

	useEmailKeyboardShortcuts(emailState, handlers, textareaRef, updateEmailState);

	const handleLastButtonKeyDown = (e: React.KeyboardEvent) => {
		if (e.key === "Tab" && !e.shiftKey) {
			e.preventDefault();
			const nextEmailIndex = email?.thread.findIndex(e => !emailState.expandedIds.has(e.id));
			if (nextEmailIndex !== undefined && nextEmailIndex !== -1) {
				const threadEmail = email?.thread[nextEmailIndex];
				if (threadEmail) {
					updateEmailState({
						expandedIds: new Set([threadEmail.id]),
					});
				}
			}
		}
	};

	const getFirstName = (fullName: string): string => {
		return fullName.split(" ")[0];
	};

	const sortedThreadEmails = useMemo(
		() => email?.thread.slice().sort((a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()),
		[email?.thread],
	);

	const activeEmail = useMemo(
		() => sortedThreadEmails?.find(e => e.id === emailState.activeId),
		[sortedThreadEmails, emailState.activeId],
	);

	useEffect(() => {
		if (!sortedThreadEmails) return;

		const handleKeyDown = (e: KeyboardEvent) => {
			// Prevent arrow key navigation when focus is inside a textarea
			const activeElement = document.activeElement as HTMLElement;
			if (activeElement && activeElement.tagName === "TEXTAREA") {
				return;
			}

			if (e.key === "ArrowDown" || e.key === "ArrowUp") {
				e.preventDefault();

				const currentIndex = sortedThreadEmails.findIndex(email => email.id === emailState.activeId);
				if (currentIndex === -1) return;

				let newIndex = currentIndex;

				if (e.key === "ArrowDown") {
					newIndex = Math.min(currentIndex + 1, sortedThreadEmails.length - 1);
				} else if (e.key === "ArrowUp") {
					newIndex = Math.max(currentIndex - 1, 0);
				}

				if (newIndex !== currentIndex) {
					const newActiveId = sortedThreadEmails[newIndex].id;
					updateEmailState({ activeId: newActiveId });

					const activeRef = emailRefs.current[newActiveId];
					if (activeRef?.current) {
						activeRef.current.scrollIntoView({
							behavior: "smooth",
							block: "center",
						});
					}
				}
			}
		};

		window.addEventListener("keydown", handleKeyDown);
		return () => window.removeEventListener("keydown", handleKeyDown);
	}, [emailState.activeId, sortedThreadEmails, updateEmailState]);

	const handleMouseEnter = (id: number) => {
		updateEmailState({ hoveredId: id });
	};

	const handleMouseLeave = () => {
		updateEmailState({ hoveredId: null });
	};

	// When activeId changes, scroll the corresponding EmailThreadItem into view
	useEffect(() => {
		const activeRef = emailRefs.current[emailState.activeId || 0];
		if (activeRef?.current) {
			activeRef.current.focus();
			activeRef.current.scrollIntoView({
				behavior: isInitialLoad.current ? undefined : "smooth",
				block: "center",
			});
			isInitialLoad.current = false;
		}
	}, [emailState.activeId]);

	const renderedThreadItems = useMemo(
		() =>
			sortedThreadEmails?.map((threadEmail, index) => (
				<EmailThreadItem
					key={threadEmail.id}
					ref={emailRefs.current[threadEmail.id]}
					threadEmail={threadEmail}
					index={index}
					isExpanded={emailState.expandedIds.has(threadEmail.id)}
					isActive={emailState.activeId === threadEmail.id}
					isHovered={emailState.hoveredId === threadEmail.id}
					isReplying={emailState.replyingToId === threadEmail.id}
					messageText={emailState.messageText}
					fullMessage={index === sortedThreadEmails.length - 1 ? email?.fullMessage : undefined}
					onSelect={() => handlers.handleEmailClick(threadEmail.id)}
					onReply={() => handlers.handleStartReply(threadEmail.id)}
					onMessageChange={text => updateEmailState({ messageText: text })}
					onSend={handlers.handleSend}
					onDiscard={() =>
						updateEmailState({
							messageText: "",
							replyingToId: null,
						})
					}
					onMouseEnter={() => handleMouseEnter(threadEmail.id)}
					onMouseLeave={handleMouseLeave}
					textareaRef={textareaRef}
				/>
			)),
		[
			sortedThreadEmails,
			emailState.expandedIds,
			emailState.activeId,
			emailState.hoveredId,
			emailState.replyingToId,
			emailState.messageText,
			handlers,
			handleMouseEnter,
			handleMouseLeave,
		],
	);

	// Initialize activeId when email changes
	useEffect(() => {
		if (sortedThreadEmails?.length) {
			const lastEmailId = sortedThreadEmails[sortedThreadEmails.length - 1].id;
			updateEmailState({
				activeId: lastEmailId,
				hoveredId: lastEmailId,
				expandedIds: new Set([lastEmailId]),
			});
		}
	}, [sortedThreadEmails]);

	// Add effect to reset state when modal closes
	useEffect(() => {
		if (!isOpen) {
			isInitialLoad.current = true;
			setEmailState({
				expandedIds: new Set(),
				activeId: null,
				replyingToId: null,
				isUnread: false,
				messageText: "",
				isLoading: false,
				hoveredId: null,
			});
		}
	}, [isOpen]);

	// Initialize with last email expanded and active whenever modal opens
	useEffect(() => {
		if (isOpen && sortedThreadEmails?.length) {
			const lastEmailId = sortedThreadEmails[sortedThreadEmails.length - 1].id;
			updateEmailState({
				activeId: lastEmailId,
				hoveredId: lastEmailId,
				expandedIds: new Set([lastEmailId]),
			});
		}
	}, [isOpen, sortedThreadEmails]);

	useEffect(() => {
		if (sortedThreadEmails) {
			sortedThreadEmails.forEach(threadEmail => {
				if (!emailRefs.current[threadEmail.id]) {
					emailRefs.current[threadEmail.id] = React.createRef<HTMLDivElement>();
				}
			});
		}
	}, [sortedThreadEmails]);

	useEffect(() => {
		const handleKeyDown = (e: KeyboardEvent) => {
			if (document.activeElement instanceof HTMLInputElement || document.activeElement instanceof HTMLTextAreaElement) {
				return;
			}

			if (e.key.toLowerCase() === "r") {
				e.preventDefault();
				if (emailState.activeId) {
					handlers.handleStartReply(emailState.activeId);
				}
			}
		};

		if (isOpen) {
			window.addEventListener("keydown", handleKeyDown);
		}
		return () => {
			window.removeEventListener("keydown", handleKeyDown);
		};
	}, [isOpen, emailState.activeId, handlers]);

	const contentRef = useRef<HTMLDivElement>(null);
	const [hasScrolled, setHasScrolled] = useState(false);

	useEffect(() => {
		const el = contentRef.current;
		if (el) {
			const onScroll = () => {
				setHasScrolled(el.scrollTop > 0);
			};
			el.addEventListener("scroll", onScroll);

			// Check initial scroll position
			setHasScrolled(el.scrollTop > 0);

			return () => {
				el.removeEventListener("scroll", onScroll);
			};
		}
	}, [email]);

	useEffect(() => {
		const handleKeyDown = (e: KeyboardEvent) => {
			if (document.activeElement instanceof HTMLInputElement || document.activeElement instanceof HTMLTextAreaElement) {
				return;
			}

			if (e.key.toLowerCase() === "j") {
				e.preventDefault();
				handlers.navigateThread("up");
			} else if (e.key.toLowerCase() === "k") {
				e.preventDefault();
				handlers.navigateThread("down");
			}
		};

		if (isOpen) {
			window.addEventListener("keydown", handleKeyDown);
		}
		return () => {
			window.removeEventListener("keydown", handleKeyDown);
		};
	}, [isOpen, handlers]);

	// Add this effect to handle scrolling when replying starts
	useEffect(() => {
		if (emailState.replyingToId && contentRef.current) {
			const emailElement = emailRefs.current[emailState.replyingToId]?.current;
			if (emailElement) {
				const emailRect = emailElement.getBoundingClientRect();
				const containerRect = contentRef.current.getBoundingClientRect();
				const scrollTop = contentRef.current.scrollTop;

				const targetPosition = scrollTop + emailRect.top - containerRect.top - containerRect.height / 10;

				setTimeout(() => {
					contentRef.current?.scrollTo({
						top: targetPosition,
						behavior: "smooth",
					});
				}, 50);
			}
		}
	}, [emailState.replyingToId]);

	if (!email) return null;

	return (
		<TooltipProvider>
			<Dialog open={isOpen} onClose={onClose} className="relative z-50">
				<div className="bg-modal-backdrop backdrop-blur-modal fixed inset-0" aria-hidden="true" />
				<div className="fixed inset-0 flex items-center justify-center p-4">
					<Dialog.Panel className="max-w-800 rounded-modal border-background-bg-border bg-background-bg-base shadow-float flex h-[90vh] w-full flex-col border-[0.5px]">
						<div className={`sticky top-0 z-10 px-8 py-6 ${hasScrolled ? "header-blur" : ""}`}>
							<div className="mb-6 flex items-center justify-between">
								<div className="flex items-center space-x-3">
									<Tooltip>
										<TooltipTrigger asChild>
											<Button
												className="shadow-low flex h-8 items-center justify-center gap-1.5 rounded-md border bg-white px-2 hover:bg-gray-100"
												onClick={onClose}>
												<img src={arrowLeft} alt="Go back" className="h-4 w-4" />
											</Button>
										</TooltipTrigger>
										<TooltipContent className="flex items-center gap-2">
											<span>Back</span>
											<kbd className="text-label-label-muted bg-background-bg-dim rounded px-2 py-0.5 text-xs">esc</kbd>
										</TooltipContent>
									</Tooltip>

									<div className="bg-background-bg-border h-4 w-0.5 rounded-full" />

									<Tooltip>
										<TooltipTrigger asChild>
											<Button
												className="flex h-8 w-8 items-center justify-center border bg-white p-1 hover:bg-gray-100"
												onClick={() => handlers.navigateThread("up")}>
												<img src={chevronSmallUp} alt="Navigate up" className="h-4 w-4" />
											</Button>
										</TooltipTrigger>
										<TooltipContent className="flex items-center gap-2">
											<span>Previous Conversation</span>
											<kbd className="text-label-label-muted bg-background-bg-dim rounded px-2 py-0.5 text-xs">J</kbd>
										</TooltipContent>
									</Tooltip>

									<Tooltip>
										<TooltipTrigger asChild>
											<Button
												className="flex h-8 w-8 items-center justify-center border bg-white p-1 hover:bg-gray-100"
												onClick={() => handlers.navigateThread("down")}>
												<img src={chevronSmallUp} alt="Navigate down" className="h-4 w-4 rotate-180" />
											</Button>
										</TooltipTrigger>
										<TooltipContent className="flex items-center gap-2">
											<span>Next Conversation</span>
											<kbd className="text-label-label-muted bg-background-bg-dim rounded px-2 py-0.5 text-xs">K</kbd>
										</TooltipContent>
									</Tooltip>
								</div>

								<div className="flex items-center">
									<Tooltip>
										<TooltipTrigger asChild>
											<Button className="flex h-8 w-8 items-center justify-center border bg-white p-1 hover:bg-gray-100">
												<img src={doubleCheckmarkBlack} alt="Mark as done" className="h-4 w-4" />
											</Button>
										</TooltipTrigger>
										<TooltipContent className="flex items-center gap-2">
											<span>Mark Done</span>
											<kbd className="text-label-label-muted bg-background-bg-dim rounded px-2 py-0.5 text-xs">E</kbd>
										</TooltipContent>
									</Tooltip>

									<div className="bg-background-bg-border mx-3 h-4 w-0.5 rounded-full" />
									<div className="flex space-x-3">
										<Tooltip>
											<TooltipTrigger asChild>
												<Button
													className="flex h-8 w-8 items-center justify-center border bg-white p-1 hover:bg-gray-100"
													onClick={() => handlers.setUnread(!emailState.isUnread)}>
													<img
														src={unread}
														alt={emailState.isUnread ? "Mark as read" : "Mark as unread"}
														className="h-4 w-4"
													/>
												</Button>
											</TooltipTrigger>
											<TooltipContent className="flex items-center gap-2">
												<span>{emailState.isUnread ? "Mark Read" : "Mark Unread"}</span>
												<kbd className="text-label-label-muted bg-background-bg-dim rounded px-2 py-0.5 text-xs">U</kbd>
											</TooltipContent>
										</Tooltip>
									</div>
								</div>
							</div>
							<div className="mb-2 flex flex-col items-start gap-3 self-stretch">
								<div className="inline-flex items-center gap-1">
									<IconSVG
										path={fileTextPath}
										width={16}
										height={16}
										viewBox="0 0 16 16"
										className="text-label-link"
										fill="currentColor"
										isActive={true}
									/>
									<span className="text-label-link text-campaign leading-campaign font-medium">
										{email.campaignTitle}
									</span>
								</div>
								<h2 className="self-stretch text-[20px] font-medium leading-[27px] text-black">{email.subject}</h2>
							</div>
						</div>
						<div ref={contentRef} className="custom-scrollbar flex-grow overflow-auto px-4">
							<div className="space-y-1 pb-4">{renderedThreadItems}</div>
						</div>
					</Dialog.Panel>
				</div>
			</Dialog>
		</TooltipProvider>
	);
};

const useEmailKeyboardShortcuts = (
	emailState: EmailState,
	handlers: EmailHandlers,
	textareaRef: React.RefObject<HTMLTextAreaElement>,
	updateEmailState: (updates: Partial<EmailState>) => void,
) => {
	useEffect(() => {
		const handleKeyDown = (e: KeyboardEvent) => {
			try {
				if (
					document.activeElement === textareaRef.current &&
					!((e.metaKey || e.ctrlKey) && (e.key === "Enter" || e.key === "."))
				) {
					return;
				}

				if (e.key === "Enter") {
					if (!emailState.activeId) {
						return;
					}

					e.preventDefault();
					const expandedIds = new Set(emailState.expandedIds);
					if (expandedIds.has(emailState.activeId)) {
						// If email is already expanded, start replying
						handlers.handleStartReply(emailState.activeId);
					} else {
						// Expand the active email without collapsing others
						expandedIds.add(emailState.activeId);
						updateEmailState({
							expandedIds,
							activeId: emailState.activeId,
						});
					}
				} else if (e.key.toLowerCase() === "o") {
					if (!emailState.activeId) {
						return;
					}

					e.preventDefault();
					const expandedIds = new Set(emailState.expandedIds);
					if (expandedIds.has(emailState.activeId)) {
						// If email is expanded, collapse it
						expandedIds.delete(emailState.activeId);
					} else {
						// If email is collapsed, expand it
						expandedIds.add(emailState.activeId);
					}
					updateEmailState({
						expandedIds,
						activeId: emailState.activeId,
					});
				}
			} catch (error) {
				console.error("Error handling keyboard shortcut:", error);
			}
		};

		window.addEventListener("keydown", handleKeyDown);
		return () => window.removeEventListener("keydown", handleKeyDown);
	}, [emailState, handlers, textareaRef, updateEmailState]);
};

export default EmailModal;
