import { useSidebar } from "@/components/ui/sidebar";
import { Tooltip, TooltipTrigger, TooltipContent } from "@/components/ui/tooltip";
import IconSVG from "@/components/IconSVG/IconSVG";
import { sidebarTogglePath } from "@/assets/icons/paths";
import { Button } from "@/components/ui/button";
import googleLogo from "@/assets/images/google-logo.png";
import microsoftLogo from "@/assets/images/microsoft-logo.png";

const EmailAccounts = () => {
	const { toggleSidebar, state } = useSidebar();

	const renderHeader = () => (
		<header>
			<div className="flex h-[52px] items-center justify-between">
				<div className="flex items-center gap-2">
					<Tooltip delayDuration={500}>
						<TooltipTrigger asChild>
							<button
								onClick={toggleSidebar}
								className="flex h-6 w-6 items-center justify-center rounded-sm hover:bg-gray-100"
								aria-label="Toggle sidebar">
								<IconSVG path={sidebarTogglePath} width={16} height={16} viewBox="0 0 16 16" fill="#666666" />
							</button>
						</TooltipTrigger>
						<TooltipContent side="right">
							{state === "expanded" ? "Collapse the sidebar (⌘B)" : "Expand the sidebar (⌘B)"}
						</TooltipContent>
					</Tooltip>
					<h1 className="text-[15px] font-medium leading-[22px] text-[--label-label-title]">Email Accounts</h1>
				</div>
			</div>
		</header>
	);

	return (
		<div className="px-4">
			{renderHeader()}
			<div className="-mx-4 h-[0.5px] bg-[--background-bg-border]" />
			<div className="flex flex-col items-center">
				<div className="w-full max-w-[555px] p-6">
					<div className="flex flex-col items-center justify-center py-8">
						<div className="inline-flex flex-col items-center gap-3">
							<h2 className="self-stretch text-center text-xl font-semibold leading-[27px] text-[--label-label-title]">
								Connect any number of email accounts.
							</h2>
							<p className="text-body-head leading-body-head text-center font-normal text-[--label-label-muted]">
								Add as many accounts as you&apos;d like. Check out{" "}
								<a
									href="https://www.za-zu.com/blog/playbook#ii-the-infrastructure"
									target="_blank"
									rel="noopener noreferrer"
									className="text-body-head leading-body-head font-medium text-[--label-label-base]">
									our guide
								</a>{" "}
								on how to best set up those accounts for maximum deliverability
							</p>
						</div>

						<div className="mt-8 flex w-[224px] flex-col items-start gap-3">
							<Button
								onClick={() => window.open(`${import.meta.env.VITE_API_URL}/mailboxes/oauth/google/start`, "_blank")}
								variant="secondary"
								className="shadow-low h-8 w-full gap-1.5 px-2 text-[13px] font-medium leading-[18px] text-[--label-label-title]">
								<img src={googleLogo} alt="Google" className="h-4 w-4 object-contain" />
								Connect Google
							</Button>
							<Button
								onClick={() => window.open(`${import.meta.env.VITE_API_URL}/mailboxes/oauth/microsoft/start`, "_blank")}
								variant="secondary"
								className="shadow-low h-8 w-full gap-1.5 px-2 text-[13px] font-medium leading-[18px] text-[--label-label-title]">
								<img src={microsoftLogo} alt="Microsoft" className="h-4 w-4 object-contain" />
								Connect Microsoft
							</Button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default EmailAccounts;
