import { useEffect, useState, useRef } from "react";
import { useCampaignStore } from "@/store";
import { Button } from "../ui/button";
import FilterModalContent from "../FilterModalContent";
import { CampaignProps } from "@/types/campaign";
import { june } from "@/utils/juneAnalytics";
import { filterData } from "@/utils/filterData";
import useGlobalFilterStore from "@/store/useGlobalFilterStore";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { StatusBadge } from "@/components/status-badge";
import { ScrollArea } from "@/components/ui/scroll-area";
import IconSVG from "../IconSVG/IconSVG";
import openedIcon from "../../assets/images/opened-icon.png";
import repliedIcon from "../../assets/images/replied-icon.png";
import { useSidebar } from "@/components/ui/sidebar";
import { Tooltip, TooltipTrigger, TooltipContent } from "@/components/ui/tooltip";
import { ProgressiveBlur } from "@/components/ui/progressive-blur";
import {
	plusButtonPath,
	companyCardPath,
	sendPaperAirplanePath,
	clickedPath,
	thumbsUpPath,
	sidebarTogglePath,
} from "@/assets/icons/paths";
import { EmptyCampaigns } from "./EmptyCampaigns";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription } from "../ui/dialog";
import { Input } from "../ui/input";

interface TableHeader {
	icon: string;
	text: string;
	iconColor: string;
	version?: string;
}

function calculatePercentage(value: number, total: number): string {
	if (total === 0) return "-";
	const percentage = (value / total) * 100;
	return percentage.toFixed(2) + "%";
}

function MetricCell({ value, total }: { value: number; total: number }) {
	const percentage = calculatePercentage(value, total);
	return (
		<TableCell className="w-[15%] p-0">
			<div className="flex h-[40px] items-center justify-between gap-1 border-r-[0.5px] border-[#E3E3E3] p-[4px_16px]">
				<span className="text-[13px] font-normal leading-[18px] text-[#1A1A1A]">{value.toLocaleString()}</span>
				<span className="whitespace-nowrap text-[11px] font-normal leading-[16px] text-[#666]">{percentage}</span>
			</div>
		</TableCell>
	);
}

const CampaignsList = () => {
	// Fetch campaign-related state and functions from the global store
	const { campaigns, getCampaigns, createNewCampaign, setSelectedCampaign, setCampaigns } = useCampaignStore();

	// Fetch filter-related state and functions from the global filter store
	const { searchText, selectedStatuses, setSelectedStatuses } = useGlobalFilterStore();

	// Local state for filtered campaigns and filter modal visibility
	const [filteredCampaigns, setFilteredCampaigns] = useState<CampaignProps[]>([]);
	const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);

	const debounceTextSearch = useRef<NodeJS.Timeout | null>(null);
	const { toggleSidebar, state } = useSidebar();

	// Add state for campaign toggle
	const [isShowingCampaigns, setIsShowingCampaigns] = useState(true);

	// Add these state near your other useState declarations
	const [showNameDialog, setShowNameDialog] = useState(false);
	const [newCampaignName, setNewCampaignName] = useState("");

	// Effect to filter campaigns based on search text and selected statuses
	useEffect(() => {
		setFilteredCampaigns(
			filterData(campaigns, searchText, (campaign, searchText) => {
				const title = campaign.title || "";
				const matchesSearch = title.toLowerCase().includes(searchText.toLowerCase());
				const matchesStatus = !selectedStatuses?.length || selectedStatuses.includes(campaign.status);
				return matchesSearch && matchesStatus;
			}),
		);
	}, [searchText, campaigns, selectedStatuses, isShowingCampaigns]);

	// Effect to fetch campaigns if none are loaded
	useEffect(() => {
		if (campaigns.length === 0 && isShowingCampaigns) {
			getCampaigns(); // Fetch campaigns from API
		}
	}, [campaigns, getCampaigns, isShowingCampaigns]);

	// New useEffect for debounced search tracking
	useEffect(() => {
		if (debounceTextSearch.current) {
			clearTimeout(debounceTextSearch.current);
		}

		debounceTextSearch.current = setTimeout(() => {
			if (searchText.trim().length > 1) {
				june?.track("search_campaigns", { search_text: searchText });
			}
		}, 2000);

		return () => {
			if (debounceTextSearch.current) {
				clearTimeout(debounceTextSearch.current);
			}
		};
	}, [searchText]);

	// Handler for creating a new campaign
	const handleNewCampaignClick = () => {
		setShowNameDialog(true);
	};

	const handleCreateCampaign = () => {
		if (!newCampaignName.trim()) return;

		createNewCampaign(newCampaignName.trim());
		june?.track("new_campaign", { name: newCampaignName });
		setShowNameDialog(false);
		setNewCampaignName("");
	};

	// Handler for closing the filter modal
	const handleFilterClose = () => {
		setIsFilterModalOpen(false);
	};

	const toggleCampaigns = () => {
		setIsShowingCampaigns(!isShowingCampaigns);
		if (!isShowingCampaigns) {
			getCampaigns(true);
		} else {
			setCampaigns([]);
		}
	};

	const renderHeader = () => (
		<header>
			<div className="flex h-[52px] items-center justify-between">
				<div className="flex items-center gap-2">
					<Tooltip delayDuration={500}>
						<TooltipTrigger asChild>
							<button
								onClick={toggleSidebar}
								className="flex h-6 w-6 items-center justify-center rounded-sm hover:bg-gray-100"
								aria-label="Toggle sidebar">
								<IconSVG path={sidebarTogglePath} width={16} height={16} viewBox="0 0 16 16" fill="#666666" />
							</button>
						</TooltipTrigger>
						<TooltipContent side="right">
							{state === "expanded" ? "Collapse the sidebar (⌘B)" : "Expand the sidebar (⌘B)"}
						</TooltipContent>
					</Tooltip>
					<h1 className="text-[15px] font-medium leading-[22px] text-[--label-label-title]">Campaigns</h1>
				</div>
				<div className="campaigns-list-actions flex gap-2">
					<Button onClick={() => setIsFilterModalOpen(true)} aria-label="Open filter options" variant="exit">
						Filter
					</Button>
					<Button onClick={handleNewCampaignClick} aria-label="Create new campaign" variant="secondary">
						<IconSVG path={plusButtonPath} width={16} height={16} viewBox="0 0 16 16" fill="currentColor" />
						New campaign
					</Button>
					{import.meta.env.MODE === "development" && (
						<Button onClick={toggleCampaigns} variant="exit">
							{isShowingCampaigns ? "Delete Campaigns (dev)" : "Create Mock Campaigns (dev)"}
						</Button>
					)}
				</div>
			</div>
		</header>
	);

	return (
		<div className="campaigns-list-wrapper px-4">
			{renderHeader()}
			<Dialog open={showNameDialog} onOpenChange={setShowNameDialog}>
				<DialogContent
					className="sm:max-w-[430px]"
					onEnterKeyPress={() => {
						if (newCampaignName.trim()) {
							handleCreateCampaign();
						}
					}}>
					<DialogHeader>
						<DialogTitle>Give your campaign a name</DialogTitle>
						<DialogDescription>Make it specific and easy to track</DialogDescription>
					</DialogHeader>
					<div className="flex flex-col gap-4">
						<Input
							placeholder="Enter campaign name..."
							value={newCampaignName}
							onChange={e => setNewCampaignName(e.target.value)}
							autoFocus
						/>
						<Button onClick={handleCreateCampaign} disabled={!newCampaignName.trim()}>
							Create campaign
						</Button>
					</div>
				</DialogContent>
			</Dialog>
			{campaigns.length === 0 && !isShowingCampaigns ? (
				<>
					<div className="-mx-4 h-[0.5px] bg-[--background-bg-border]" />
					<EmptyCampaigns onCreateCampaign={handleNewCampaignClick} />
				</>
			) : (
				<div className="relative">
					<ScrollArea className="h-[calc(100vh-100px)]">
						<div className="campaigns-list-table w-full overflow-hidden rounded-lg border">
							<Table>
								<TableHeader>
									<TableRow className="bg-gray-50">
										<TableHead className="w-1/4 p-0">
											<div className="flex h-[40px] items-center gap-3 border-r-[0.5px] border-[#E3E3E3] p-[4px_16px]">
												<IconSVG path={companyCardPath} width={14} height={14} viewBox="0 0 14 14" fill="#1A1A1A" />
												<span className="text-label-title text-[11px] font-medium leading-[16px]">Name</span>
											</div>
										</TableHead>
										<TableHead className="w-[15%] p-0">
											<div className="flex h-[40px] items-center gap-3 border-r-[0.5px] border-[#E3E3E3] p-[4px_16px]">
												<IconSVG
													path={sendPaperAirplanePath}
													width={14}
													height={14}
													viewBox="0 0 14 14"
													fill="#AAAAAA"
												/>
												<span className="text-label-title whitespace-nowrap text-[11px] font-medium leading-[16px]">
													Sent
												</span>
											</div>
										</TableHead>
										<TableHead className="w-[15%] p-0">
											<div className="flex h-[40px] items-center gap-3 border-r-[0.5px] border-[#E3E3E3] p-[4px_16px]">
												<IconSVG path={clickedPath} width={15} height={14} viewBox="0 0 15 14" fill="#606ACB" />
												<span className="text-label-title whitespace-nowrap text-[11px] font-medium leading-[16px]">
													Clicked
												</span>
											</div>
										</TableHead>
										<TableHead className="w-[15%] p-0">
											<div className="flex h-[40px] items-center gap-3 border-r-[0.5px] border-[#E3E3E3] p-[4px_16px]">
												<img src={openedIcon} alt="Opened icon" width={14} height={14} />
												<span className="text-label-title whitespace-nowrap text-[11px] font-medium leading-[16px]">
													Opened
												</span>
											</div>
										</TableHead>
										<TableHead className="w-[15%] p-0">
											<div className="flex h-[40px] items-center gap-3 border-r-[0.5px] border-[#E3E3E3] p-[4px_16px]">
												<img src={repliedIcon} alt="Replied icon" width={14} height={14} />
												<span className="text-label-title whitespace-nowrap text-[11px] font-medium leading-[16px]">
													Replied
												</span>
											</div>
										</TableHead>
										<TableHead className="w-[15%] p-0">
											<div className="flex h-[40px] items-center gap-3 border-r-[0.5px] border-[#E3E3E3] p-[4px_16px]">
												<IconSVG path={thumbsUpPath} width={15} height={14} viewBox="0 0 15 14" fill="#5FA97B" />
												<span className="text-label-title whitespace-nowrap text-[11px] font-medium leading-[16px]">
													Positive Reply
												</span>
											</div>
										</TableHead>
									</TableRow>
								</TableHeader>
								<TableBody>
									{filteredCampaigns.map((campaign, index) => (
										<TableRow
											key={campaign.id}
											className={`${index % 2 === 0 ? "bg-white" : "bg-gray-50"} cursor-pointer hover:bg-[--background-bg-dim-hover]`}
											onClick={() => setSelectedCampaign(campaign)}>
											<TableCell className="w-1/4 p-0">
												<div className="flex h-[40px] items-center justify-between gap-1 border-r-[0.5px] border-[#E3E3E3] p-[4px_16px]">
													<span className="flex-grow truncate">{campaign.title}</span>
													<StatusBadge
														status={
															campaign.status.toLowerCase() as "active" | "draft" | "completed" | "paused" | "stopped"
														}
													/>
												</div>
											</TableCell>
											<TableCell className="w-[15%] p-0">
												<div className="flex h-[40px] items-center border-r-[0.5px] border-[#E3E3E3] p-[4px_16px]">
													<span className="text-[13px] font-normal leading-[18px] text-[#1A1A1A]">
														{campaign.campaignMetrics.sent.toLocaleString()}
													</span>
												</div>
											</TableCell>
											<MetricCell value={campaign.campaignMetrics.clicked} total={campaign.campaignMetrics.sent} />
											<MetricCell value={campaign.campaignMetrics.opened} total={campaign.campaignMetrics.sent} />
											<MetricCell value={campaign.campaignMetrics.replied} total={campaign.campaignMetrics.sent} />
											<MetricCell
												value={campaign.campaignMetrics.positiveReplies}
												total={campaign.campaignMetrics.sent}
											/>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</div>
					</ScrollArea>
					<ProgressiveBlur
						orientation="vertical"
						className="pointer-events-none absolute bottom-0 h-16 w-full"
						style={{ background: "linear-gradient(to top, white, transparent)" }}
					/>
				</div>
			)}
		</div>
	);
};

export default CampaignsList;
