import { OrganizationList, SignOutButton, useOrganization } from "@clerk/clerk-react";
import { SidebarProvider } from "@/components/ui/sidebar";
import NewSidebar from "@/components/NewSidebar";

const OrganizationPickerScreen = () => {
	return (
		<div className="flex h-screen flex-col items-center justify-center gap-6">
			<OrganizationList hidePersonal />
			<p className="max-w-xs text-center text-sm font-bold">
				To join another organization, request access from your admin.
			</p>
			<SignOutButton>Sign out?</SignOutButton>
		</div>
	);
};

export const App = ({ children }: { children: React.ReactNode }) => {
	const { organization } = useOrganization();

	if (!organization) return <OrganizationPickerScreen />;

	return (
		<SidebarProvider defaultOpen>
			<div className="flex h-screen overflow-hidden">
				<NewSidebar />

				{/* Main content wrapper */}
				<main className="flex-1 overflow-auto">
					{/* Gray background container */}
					<div className="h-full bg-[#F9F9F9] py-2 pr-2">
						{/* Content card */}
						<div className="h-full rounded-lg border-[0.5px] border-[#D9D9D9] bg-white shadow-[0px_1px_1px_0px_rgba(0,0,0,0.06),0px_4px_4px_-1px_rgba(0,0,0,0.02)]">
							{children}
						</div>
					</div>
				</main>
			</div>
			<div className="fixed bottom-4 left-4 h-fit w-[68px]"></div>
		</SidebarProvider>
	);
};
