export const bulletListPath = [
	{
		d: "M3.5 2.33325C2.5335 2.33325 1.75 3.11676 1.75 4.08325C1.75 5.04975 2.5335 5.83325 3.5 5.83325C4.4665 5.83325 5.25 5.04975 5.25 4.08325C5.25 3.11676 4.4665 2.33325 3.5 2.33325Z",
		fill: "#666666",
	},
	{
		d: "M7.58333 3.49992C7.26116 3.49992 7 3.76109 7 4.08325C7 4.40542 7.26116 4.66659 7.58333 4.66659H11.6667C11.9888 4.66659 12.25 4.40542 12.25 4.08325C12.25 3.76109 11.9888 3.49992 11.6667 3.49992H7.58333Z",
		fill: "#666666",
	},
	{
		d: "M3.5 8.16659C2.5335 8.16659 1.75 8.95006 1.75 9.91659C1.75 10.8831 2.5335 11.6666 3.5 11.6666C4.4665 11.6666 5.25 10.8831 5.25 9.91659C5.25 8.95006 4.4665 8.16659 3.5 8.16659Z",
		fill: "#666666",
	},
	{
		d: "M7.58333 9.33325C7.26116 9.33325 7 9.59441 7 9.91659C7 10.2388 7.26116 10.4999 7.58333 10.4999H11.6667C11.9888 10.4999 12.25 10.2388 12.25 9.91659C12.25 9.59441 11.9888 9.33325 11.6667 9.33325H7.58333Z",
		fill: "#666666",
	},
];
