"use client";

import { Badge, BadgeProps } from "@/components/ui/badge";
import { cn } from "@/lib/utils";

interface StatusBadgeProps extends BadgeProps {
	status: "active" | "draft" | "completed" | "paused" | "stopped";
}

const statusStyles = {
	active: "bg-green-100 text-green-800 border-green-300 hover:bg-green-200 font-medium",
	draft: "bg-gray-100 text-gray-700 border-gray-300 hover:bg-gray-200 font-medium",
	completed: "bg-blue-100 text-blue-800 border-blue-300 hover:bg-blue-200 font-medium",
	paused: "bg-yellow-100 text-yellow-800 border-yellow-300 hover:bg-yellow-200 font-medium",
	stopped: "bg-red-100 text-red-800 border-red-300 hover:bg-red-200 font-medium",
};

export function StatusBadge({ status, className, ...props }: StatusBadgeProps) {
	return (
		<Badge variant="outline" className={cn(statusStyles[status], className)} {...props}>
			{status.charAt(0).toUpperCase() + status.slice(1)}
		</Badge>
	);
}
