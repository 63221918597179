"use client";

import { UnderConstruction } from "@/components";
import React from "react";

const Analytics = () => {
	return (
		<div className="w-full">
			<UnderConstruction
				title="Global Analytics"
				description="Understand everything about your outbound with our global analytics dashboard."
			/>
		</div>
	);
};

export default Analytics;
