import * as React from "react";
import { cn } from "@/lib/utils";

interface ProgressiveBlurProps extends React.HTMLAttributes<HTMLDivElement> {
	orientation?: "horizontal" | "vertical";
}

export const ProgressiveBlur = React.forwardRef<HTMLDivElement, ProgressiveBlurProps>(
	({ className, orientation = "vertical", ...props }, ref) => {
		return (
			<div
				ref={ref}
				className={cn(
					"relative z-50",
					orientation === "vertical" && "h-full",
					orientation === "horizontal" && "w-full",
					className,
				)}
				{...props}
			/>
		);
	},
);

ProgressiveBlur.displayName = "ProgressiveBlur";
