import { Email } from "@/types/inbox";
import { generateRandomEmails } from "@/utils/generators";
import { create } from "zustand";
import { persist } from "zustand/middleware";

type InboxStoreProps = {
	primary: Email[];
	sent: Email[];
	done: Email[];
	setPrimary: (value: Email[]) => void;
	setSent: (value: Email[]) => void;
	setDone: (value: Email[]) => void;
	fetchInbox: () => void;
};

const useInboxStore = create<InboxStoreProps>()(
	persist(
		set => ({
			primary: [],
			sent: [],
			done: [],
			setPrimary: value => set({ primary: value }),
			setSent: value => set({ sent: value }),
			setDone: value => set({ done: value }),
			fetchInbox: async () => {
				try {
					const primary = await generateRandomEmails(15);
					const sent = await generateRandomEmails(10);
					const done = await generateRandomEmails(5);
					set({ primary, sent, done });
				} catch (error) {
					console.error("Error generating random emails:", error);
					// Set empty arrays as fallback
					set({ primary: [], sent: [], done: [] });
				}
			},
		}),
		{
			name: "inbox-storage",
		},
	),
);

export default useInboxStore;
