import { useState, useEffect } from "react";
import { Email } from "@/types/inbox";
import { Button } from "../ui/button";
import { InboxTabs } from "@/app/enums/inbox.enum";
import { formatTimestamp } from "@/utils/helpers";
import EmailModal from "../EmailModal/EmailModal";
import IconSVG from "../IconSVG/IconSVG";
import { doubleCheckmarkPath, letterDotPath, letterCheckPath } from "@/assets/icons/paths";
import { Tooltip, TooltipContent, TooltipTrigger, TooltipProvider } from "../ui/tooltip";

interface InboxListProps {
	emails: Email[];
	activeTab: InboxTabs;
	onModalStateChange: (isOpen: boolean) => void;
}

const InboxList = ({ emails, activeTab, onModalStateChange }: InboxListProps) => {
	const [hoveredRow, setHoveredRow] = useState<number | null>(null);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [selectedEmail, setSelectedEmail] = useState<Email | null>(null);

	const getThreadButtons = (email: Email) => {
		const isFirstEmail = emails.indexOf(email) === 0;

		return (
			<div
				className="border-background-bg-border backdrop-blur-modal shadow-low flex items-center gap-1 rounded-lg border-[0.5px] bg-white/80 p-1"
				onClick={e => e.stopPropagation()}>
				<TooltipProvider delayDuration={0} disableHoverableContent>
					<Tooltip>
						<TooltipTrigger asChild>
							<Button
								variant="inboxAction"
								className="hover:bg-background-bg-dim-hover hover:shadow-low flex h-6 w-6 cursor-pointer items-center justify-center px-1 transition-all hover:rounded-[5px]">
								<IconSVG
									path={doubleCheckmarkPath}
									width={14}
									height={14}
									viewBox="0 0 14 14"
									fill={email.isDone ? "#397AF1" : "#666666"}
									className={email.isDone ? "text-[#397AF1]" : "text-gray-500"}
								/>
							</Button>
						</TooltipTrigger>
						<TooltipContent side={isFirstEmail ? "bottom" : "top"}>
							{email.isDone ? "Mark not Done" : "Mark Done"} <kbd>E</kbd>
						</TooltipContent>
					</Tooltip>
				</TooltipProvider>

				<TooltipProvider delayDuration={0} disableHoverableContent>
					<Tooltip>
						<TooltipTrigger asChild>
							<Button
								variant="inboxAction"
								className="hover:bg-background-bg-dim-hover hover:shadow-low flex h-6 w-6 cursor-pointer items-center justify-center px-1 transition-all hover:rounded-[5px]">
								<IconSVG
									path={email.isRead ? letterCheckPath : letterDotPath}
									width={14}
									height={14}
									viewBox="0 0 14 14"
									className={email.isRead ? "text-[#397AF1]" : "text-gray-500"}
								/>
							</Button>
						</TooltipTrigger>
						<TooltipContent side={isFirstEmail ? "bottom" : "top"}>
							{email.isRead ? "Mark Unread" : "Mark Read"} <kbd>U</kbd>
						</TooltipContent>
					</Tooltip>
				</TooltipProvider>
			</div>
		);
	};

	const handleEmailClick = (email: Email) => {
		setSelectedEmail(email);
		setHoveredRow(email.id);
		setIsModalOpen(true);
	};

	const handleMarkDone = (email: Email, e: React.MouseEvent) => {
		e.stopPropagation();
		email.isDone = !email.isDone;
		// TODO: Add API call to update email state
	};

	useEffect(() => {
		const handleKeyDown = (e: KeyboardEvent) => {
			if (isModalOpen) return;

			// Ignore hotkeys if user is typing in an input or textarea
			if (document.activeElement instanceof HTMLInputElement || document.activeElement instanceof HTMLTextAreaElement) {
				return;
			}

			if (e.key === "Enter" && hoveredRow !== null) {
				const emailToOpen = emails.find(email => email.id === hoveredRow);
				if (emailToOpen) {
					setSelectedEmail(emailToOpen);
					setIsModalOpen(true);
				}
			}

			const currentIndex = emails.findIndex(email => email.id === hoveredRow);

			if (e.key === "ArrowDown" || e.key.toLowerCase() === "k") {
				e.preventDefault();
				// Don't do anything if we're at the last email
				if (currentIndex === emails.length - 1) return;

				const newIndex = currentIndex === -1 ? 0 : currentIndex + 1;
				setHoveredRow(emails[newIndex].id);
			} else if (e.key === "ArrowUp" || e.key.toLowerCase() === "j") {
				e.preventDefault();
				// Don't do anything if we're at the first email
				if (currentIndex === 0) return;

				const newIndex = currentIndex === -1 ? emails.length - 1 : currentIndex - 1;
				setHoveredRow(emails[newIndex].id);
			}
		};

		window.addEventListener("keydown", handleKeyDown);
		return () => window.removeEventListener("keydown", handleKeyDown);
	}, [hoveredRow, emails, setSelectedEmail, setIsModalOpen, isModalOpen]);

	const getActiveRow = (emailId: number) => hoveredRow === emailId;

	useEffect(() => {
		onModalStateChange(isModalOpen);
	}, [isModalOpen, onModalStateChange]);

	const handleNavigateThread = (currentEmail: Email, direction: "up" | "down") => {
		const currentIndex = emails.findIndex(email => email.id === currentEmail.id);
		if (currentIndex === -1) return;

		if ((direction === "up" && currentIndex === 0) || (direction === "down" && currentIndex === emails.length - 1)) {
			setIsModalOpen(false);
			return;
		}

		const nextIndex =
			direction === "up" ? Math.max(0, currentIndex - 1) : Math.min(emails.length - 1, currentIndex + 1);

		setSelectedEmail(emails[nextIndex]);
		setHoveredRow(emails[nextIndex].id);
	};

	return (
		<div className="h-full w-full overflow-hidden bg-white p-0 shadow">
			<div className="w-full">
				{emails.length > 0 ? (
					emails.map(email => (
						<div
							key={email.id}
							className={`group grid h-12 cursor-pointer items-center border-l-4 pr-4 ${
								getActiveRow(email.id) ? "border-label-link bg-gray-200" : "border-transparent"
							}`}
							onMouseMove={() => {
								setHoveredRow(email.id);
							}}
							onClick={() => handleEmailClick(email)}
							style={{
								gridTemplateColumns: "56px 200px 1fr 100px",
							}}>
							<div className="flex h-full items-center justify-center pl-8 pr-0">
								<div className="h-2 w-2 rounded-full bg-[#397AF1]" />
							</div>
							<div className="flex items-center font-medium">
								{email.companyName ? `${email.name} (${email.companyName})` : email.name}
							</div>
							<div className="flex min-w-0 flex-1 items-center gap-8">
								<div className="line-clamp-1 shrink-0 font-normal text-[--label-label-base]">{email.campaignTitle}</div>

								<div className="text-preview leading-preview text-label-label-muted line-clamp-1 min-w-0 flex-1 font-normal">
									{email.messagePreview}
								</div>
							</div>
							<div className="flex w-[100px] items-center justify-end">
								{hoveredRow === email.id ? (
									getThreadButtons(email)
								) : (
									<span className="text-timestamp leading-timestamp text-label-label-muted block w-full text-right">
										{formatTimestamp(email.timestamp)}
									</span>
								)}
							</div>
						</div>
					))
				) : (
					<div className="flex h-full items-center justify-center text-gray-400">No emails found.</div>
				)}
			</div>
			{selectedEmail && (
				<EmailModal
					isOpen={isModalOpen}
					onClose={() => setIsModalOpen(false)}
					email={selectedEmail}
					onNavigateThread={direction => handleNavigateThread(selectedEmail, direction)}
				/>
			)}
		</div>
	);
};

export default InboxList;
