import React from "react";

interface InboxIconProps {
	isActive?: boolean;
	unreadCount?: number;
}

export const InboxIcon: React.FC<InboxIconProps> = ({ isActive = false, unreadCount = 6 }) => {
	const color = isActive ? "#1A1A1A" : "#AAAAAA";

	return (
		<svg width="16" height="16" viewBox="0 0 16 16" fill="none">
			{unreadCount > 0 && <circle cx="13.5" cy="2.5" r="2.5" fill="#0066FF" />}

			<path
				fillRule="evenodd"
				clipRule="evenodd"
				fill={color}
				d={
					unreadCount > 0
						? "M15.2892 6.07849L15.8944 7.28885C15.9639 7.42771 16 7.58082 16 7.73607V12C16 13.3807 14.8807 14.5 13.5 14.5H2.5C1.11929 14.5 0 13.3807 0 12V7.73607C0 7.58082 0.0361451 7.42771 0.105573 7.28885L3 1.5H9.62602C9.54375 1.81962 9.5 2.1547 9.5 2.5C9.5 2.66933 9.51052 2.8362 9.53095 3H3.92705L1.67705 7.5H6H6.75V8.25C6.75 8.94036 7.30964 9.5 8 9.5C8.69036 9.5 9.25 8.94036 9.25 8.25V7.5H10H14.3229L13.8168 6.48764C14.3427 6.44643 14.8401 6.3035 15.2892 6.07849ZM1.5 12V9H5.35352C5.67998 10.1543 6.74122 11 8 11C9.25878 11 10.32 10.1543 10.6465 9H14.5V12C14.5 12.5523 14.0523 13 13.5 13H2.5C1.94772 13 1.5 12.5523 1.5 12Z"
						: "M1.67705 7.5L3.92705 3H12.0729L14.3229 7.5H10H9.25V8.25C9.25 8.94036 8.69036 9.5 8 9.5C7.30964 9.5 6.75 8.94036 6.75 8.25V7.5H6H1.67705ZM1.5 9V12C1.5 12.5523 1.94772 13 2.5 13H13.5C14.0523 13 14.5 12.5523 14.5 12V9H10.6465C10.32 10.1543 9.25878 11 8 11C6.74122 11 5.67998 10.1543 5.35352 9H1.5ZM3 1.5H13L15.8944 7.28885C15.9639 7.42771 16 7.58082 16 7.73607V12C16 13.3807 14.8807 14.5 13.5 14.5H2.5C1.11929 14.5 0 13.3807 0 12V7.73607C0 7.58082 0.0361451 7.42771 0.105573 7.28885L3 1.5Z"
				}
			/>
		</svg>
	);
};
